import React, { useState, ReactElement } from "react";
import { Select, SelectChangeEvent } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";

const YEAR = new Date().getFullYear();

const MONTHS = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sept",
  "Oct",
  "Nov",
  "Dec",
];

type DatePickerProps = {
  defaultDay?: string;
  defaultMonth?: string;
  defaultYear?: string;
  onMonthChange: (val: string) => void;
  onDayChange: (val: string) => void;
  onYearChange: (val: string) => void;
};

const DatePicker = ({
  defaultDay,
  defaultMonth,
  defaultYear,
  onMonthChange,
  onDayChange,
  onYearChange,
}: DatePickerProps): ReactElement => {
  const [month, setMonth] = useState<string>(defaultMonth || "0");
  const [day, setDay] = useState<string>(defaultDay || "1");
  const [year, setYear] = useState<string>(defaultYear || `${YEAR}`);

  const handleChangeMonth = (event: SelectChangeEvent<string>) => {
    setMonth(event.target.value as string);
    onMonthChange(event.target.value as string);
  };
  const handleChangeDay = (event: SelectChangeEvent<string>) => {
    setDay(event.target.value as string);
    onDayChange(event.target.value as string);
  };
  const handleChangeYear = (event: SelectChangeEvent<string>) => {
    setYear(event.target.value as string);
    onYearChange(event.target.value as string);
  };
  return (
    <div style={{ display: "flex", flex: 1, flexDirection: "row" }}>
      <Select
        labelId="date-picker-month"
        id="date-picker-month"
        value={month}
        variant="outlined"
        style={{ display: "flex", flex: 1 }}
        onChange={handleChangeMonth}>
        {MONTHS.map((month, idx) => {
          return (
            <MenuItem key={`month-${month}`} value={`${idx}`}>
              {month}
            </MenuItem>
          );
        })}
      </Select>
      <Select
        labelId="date-picker-day"
        id="date-picker-day"
        value={day}
        variant="outlined"
        style={{ display: "flex", flex: 1, marginLeft: 10 }}
        onChange={handleChangeDay}>
        {new Array(31).fill(1).map((_, idx) => {
          return (
            <MenuItem key={`day-${idx}`} value={idx + 1}>
              {idx + 1}
            </MenuItem>
          );
        })}
      </Select>
      <Select
        labelId="date-picker-year"
        id="date-picker-year"
        value={year}
        variant="outlined"
        style={{ display: "flex", flex: 1, marginLeft: 10 }}
        onChange={handleChangeYear}>
        {new Array(100).fill(1).map((_, idx) => {
          return (
            <MenuItem key={`year-${idx}`} value={`${YEAR - idx}`}>{`${
              YEAR - idx
            }`}</MenuItem>
          );
        })}
      </Select>
    </div>
  );
};

export default DatePicker;
