export const GENDER_IDENTITIES = {
  "decline-specify": "Decline to specify",
  male: "Male",
  female: "Female",
  "non-binary": "Non-binary",
};

const getKeyValue = <T extends unknown, U extends keyof T>(obj: T) => (
  key: U,
) => obj[key];

type Key = keyof typeof GENDER_IDENTITIES;

export const mapGenderToChild = (
  cb: (key: string, val: string | undefined) => unknown,
): Array<unknown> => {
  return Object.keys(GENDER_IDENTITIES).map((key) =>
    cb(key, getKeyValue(GENDER_IDENTITIES)(key as Key)),
  );
};

export const renderGenderIdentity = (id: string): string => {
  return GENDER_IDENTITIES[id as Key]
    ? GENDER_IDENTITIES[id as Key]
    : "Decline to specify";
};
