import React, { ReactElement, useEffect, useState } from "react";

import { Amplify, Hub } from "aws-amplify";
import { AuthState, onAuthUIStateChange } from "@aws-amplify/ui-components";
import awsconfig from "./aws-exports";
import MainApp from "./containers/MainApp";
import { BrowserRouter as Router } from "react-router-dom";
import SignIn from "./containers/Authentication/SignIn";
import SignUp from "./containers/Authentication/Register";
import ConfirmSignUp from "./containers/Authentication/ConfirmSignUp";
import { AmplifyProvider, Authenticator } from "@aws-amplify/ui-react";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { HubCapsule } from "@aws-amplify/core/lib/Hub";
import {
  UI_AUTH_CHANNEL,
  AUTH_STATE_CHANGE_EVENT,
} from "@aws-amplify/ui-components";
import Box from "@mui/material/Box";
import { ThemeProvider } from "@mui/system";

import theme from "./theme";
import ResetPassword from "./containers/Authentication/ResetPassword";
import ForgotPassword from "./containers/Authentication/ForgotPassword";
import Alert from "@mui/material/Alert";

Amplify.configure({
  ...awsconfig,
  Analytics: {
    disabled: true,
  },
});

const App = (): React.ReactElement => {
  const { user } = useAuthenticator((context) => [context.user]);
  const [route, setRoute] = useState<string>("signin");
  const [email, setEmail] = useState<string>("");
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(!!user);
  const [hasResetPassword, setHasResetPassword] = useState<boolean>(false);
  const parseQuery = (queryString: string): Record<string, string> => {
    const query: Record<string, string> = {};
    const pairs = (
      queryString[0] === "?" ? queryString.slice(1) : queryString
    ).split("&");
    for (let i = 0; i < pairs.length; i++) {
      const pair = pairs[i].split("=");
      query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || "");
    }
    return query;
  };

  useEffect(() => {
    const listener = (data: HubCapsule) => {
      const { payload } = data;
      if (payload.event === AUTH_STATE_CHANGE_EVENT) {
        if (payload.data && payload.data.email) {
          setEmail(payload.data.email);
        }
        if (payload.data && payload.data.resetPassword) {
          setHasResetPassword(true);
        } else {
          setHasResetPassword(false);
        }
        setRoute(payload.message || "signin");
      }
    };
    const authListener = (data: HubCapsule) => {
      const { payload } = data;
      if (payload.event === "SIGN_IN") {
        setIsLoggedIn(true);
      }
    };
    Hub.listen(AUTH_STATE_CHANGE_EVENT, authListener);
    Hub.listen(UI_AUTH_CHANNEL, listener);
    return () => {
      Hub.remove(UI_AUTH_CHANNEL, listener);
    };
  }, []);

  useEffect(() => {
    setIsLoggedIn(!!user);
  }, [user]);

  useEffect(() => {
    const query = parseQuery(window.location.search);

    if (query["redirect_url"]) {
      localStorage.setItem("redirect_url", query["redirect_url"]);
    }

    onAuthUIStateChange((nextAuthState) => {
      const redirect_url = localStorage.getItem("redirect_url");
      if (
        nextAuthState === AuthState.SignIn &&
        redirect_url &&
        redirect_url.length > 0
      ) {
        window.location.href = redirect_url;
        localStorage.removeItem("redirect_url");
      }
    });
  }, []);

  const renderAuthFlow = () => {
    if (isLoggedIn) {
      return null;
    }
    console.log("got the route", route);
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          height: "50vh",
          minHeight: 500,
          alignItems: "center",
          justifyContent: "center",
          flex: 1,
        }}>
        {hasResetPassword && <Alert severity="success">Password reset!</Alert>}
        {route === "resettingpassword" && (
          <ResetPassword slot="reset-password" email={email} />
        )}
        {route === "forgotpassword" && (
          <ForgotPassword slot="forgot-password" />
        )}
        {route === "signin" && <SignIn slot="sign-in" email={email} />}
        {route === "signup" && <SignUp slot="sign-up" />}
        {route === "confirmSignUp" && (
          <ConfirmSignUp slot="confirm-sign-up" email={email} />
        )}
      </Box>
    );
  };

  return (
    <AmplifyProvider>
      {renderAuthFlow()}
      {isLoggedIn && (
        <Router>
          <MainApp />
        </Router>
      )}
    </AmplifyProvider>
  );
};

const Wrapped = (): ReactElement => (
  <Authenticator.Provider>
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
  </Authenticator.Provider>
);
export default Wrapped;
