import React, { ReactElement } from "react";
import { useQuery } from "react-query";
import { useStore } from "src/contexts/StoreContext";
import { getMembersByUserId } from "./backend";

import { Link /*useHistory*/ } from "react-router-dom";

// Table
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { round } from "lodash";
import { Typography } from "@mui/material";
import Box from "@mui/system/Box";

const useMembers = (userId?: string) => {
  return useQuery(
    ["members", userId],
    async () => {
      try {
        if (userId) {
          const members = await getMembersByUserId(userId);
          return members;
        }
        return [];
      } catch (e) {
        console.log("[ERROR] error using members", e);
        return [];
      }
    },
    {
      refetchOnWindowFocus: false,
    },
  );
};

const ClubList = (): ReactElement => {
  const { currentUser } = useStore();

  // const history = useHistory();

  const { data: members, status: memberStatus } = useMembers(currentUser?.id);

  if (memberStatus === "loading") {
    return <div>Loading...</div>;
  }

  if (members) {
    return (
      <Box maxWidth="md" margin="0 auto">
        <Typography variant="h5" mb={2}>
          My memberships
        </Typography>

        <div className="card mt-3">
          <div className="card-body">
            {members.length === 0 && <h3>No Memberships</h3>}
            <TableContainer component={Paper}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Club Name</TableCell>
                    <TableCell align="right">Score</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {members.map((member) => {
                    const { club } = member;
                    return (
                      <TableRow key={`member-${member.id}`}>
                        <TableCell component="th" scope="row">
                          <Link to={`/clubs/${club.id}`}>{club.name}</Link>
                        </TableCell>
                        <TableCell align="right">
                          <Typography variant="h5">
                            {round(member.totalScore, 1)}{" "}
                            <Typography
                              variant="body1"
                              component="span"
                              textTransform="lowercase">
                              {club.scoreUnits}
                            </Typography>
                          </Typography>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      </Box>
    );
  }
  return <div></div>;
};

export default ClubList;
