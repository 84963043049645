import Breadcrumbs from "@mui/material/Breadcrumbs";
import React, { ReactElement } from "react";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import { useQuery } from "react-query";
import { useHistory, useParams } from "react-router-dom";
import { getClubById, getMemberByClubIdAndUserId } from "./backend";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import { useStore } from "src/contexts/StoreContext";
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";
import { round } from "lodash";
import Box from "@mui/material/Box";
import { Alert, CircularProgress } from "@mui/material";

type ClubHomeParams = {
  clubId: string;
};

const useClub = (clubId: string) => {
  return useQuery(["club", clubId], async () => {
    const club = await getClubById(clubId);
    return club;
  });
};

const useMember = (clubId: string, userId?: string) => {
  return useQuery(["member", clubId, userId], async () => {
    if (!userId) {
      return null;
    }
    const member = await getMemberByClubIdAndUserId(clubId, userId);
    return member;
  });
};

const ClubHome = (): ReactElement => {
  const history = useHistory();
  const { clubId } = useParams<ClubHomeParams>();
  const { currentUser } = useStore();
  const { data: club, isError: clubIsError } = useClub(clubId);

  const {
    data: member,
    isError: memberIsError,
    status: memberStatus,
  } = useMember(clubId, currentUser?.id);

  const renderMember = () => {
    if (club && !member && memberStatus !== "loading") {
      return (
        <CardActions>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            size="large"
            onClick={() => history.push(`/clubs/${club.id}/join`)}>
            Join
          </Button>
        </CardActions>
      );
    }

    return (
      <Box
        sx={{
          textAlign: "center",
          alignItems: "center",
          justifyContent: "center",
        }}>
        <Typography variant="h5">My score:</Typography>
        {!member && <CircularProgress />}
        {member && (
          <Typography variant="h1" fontWeight="bold" lineHeight={0.444} mt={5}>
            {round(member.totalScore, 1)}{" "}
            <Typography
              variant="h3"
              fontWeight="light"
              component="span"
              textTransform="lowercase">
              {club?.scoreUnits}
            </Typography>
            {member.totalScore === 0 && (
              <SentimentVeryDissatisfiedIcon sx={{ fontSize: "70px" }} />
            )}
          </Typography>
        )}
      </Box>
    );
  };

  const renderAddress = () => {
    if (!club) {
      return <CircularProgress />;
    }
    const zip =
      club.zipcode && club.zipcode.length > 0 ? `(${club.zipcode})` : "";
    const displayAddress = club.address ? `${club.address}, ` : "";
    const displayCity = club.city ? `${club.city}, ` : "";
    return (
      <Typography color="textSecondary">
        {displayAddress}
        {displayCity}
        {club.state} {zip}
      </Typography>
    );
  };

  if (clubIsError) {
    return (
      <Alert severity="error" sx={{ fontSize: 18 }}>
        Error getting the club. Please try again.
      </Alert>
    );
  }
  if (memberIsError) {
    return (
      <Alert severity="error" sx={{ fontSize: 18 }}>
        Error getting your membership. Please try again.
      </Alert>
    );
  }

  return (
    <Box maxWidth="md" margin="0 auto">
      <Breadcrumbs aria-label="breadcrumb">
        <Link color="inherit" href="/clubs">
          Clubs
        </Link>
        {club && <Typography color="textPrimary">{club.name}</Typography>}
      </Breadcrumbs>
      <Card variant="outlined" sx={{ marginTop: 2 }}>
        <CardContent>
          {club && (
            <Typography variant="h5" component="h2">
              {club.name}
            </Typography>
          )}
          {renderAddress()}
        </CardContent>
        {renderMember()}
        <Box sx={{ textAlign: "center", backgroundColor: "#eef", padding: 2 }}>
          To check-in, please use the tablet provided by the club.
        </Box>
      </Card>
    </Box>
  );
};

export default ClubHome;
