/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getClubSecret = /* GraphQL */ `
  query GetClubSecret($id: ID!) {
    getClubSecret(id: $id) {
      id
      clubID
      secret
      createdAt
      updatedAt
    }
  }
`;
export const listClubSecrets = /* GraphQL */ `
  query ListClubSecrets(
    $filter: ModelClubSecretFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listClubSecrets(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        clubID
        secret
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const clubSecretByClubId = /* GraphQL */ `
  query ClubSecretByClubId(
    $clubID: String
    $sortDirection: ModelSortDirection
    $filter: ModelClubSecretFilterInput
    $limit: Int
    $nextToken: String
  ) {
    clubSecretByClubID(
      clubID: $clubID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        clubID
        secret
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const clubSecretBySecret = /* GraphQL */ `
  query ClubSecretBySecret(
    $secret: String
    $sortDirection: ModelSortDirection
    $filter: ModelClubSecretFilterInput
    $limit: Int
    $nextToken: String
  ) {
    clubSecretBySecret(
      secret: $secret
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        clubID
        secret
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getMemberSecret = /* GraphQL */ `
  query GetMemberSecret($id: ID!) {
    getMemberSecret(id: $id) {
      id
      memberID
      secret
      createdAt
      updatedAt
    }
  }
`;
export const listMemberSecrets = /* GraphQL */ `
  query ListMemberSecrets(
    $filter: ModelMemberSecretFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMemberSecrets(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        memberID
        secret
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const memberSecretByMemberId = /* GraphQL */ `
  query MemberSecretByMemberId(
    $memberID: String
    $sortDirection: ModelSortDirection
    $filter: ModelMemberSecretFilterInput
    $limit: Int
    $nextToken: String
  ) {
    memberSecretByMemberID(
      memberID: $memberID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        memberID
        secret
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const memberSecretBySecret = /* GraphQL */ `
  query MemberSecretBySecret(
    $secret: String
    $sortDirection: ModelSortDirection
    $filter: ModelMemberSecretFilterInput
    $limit: Int
    $nextToken: String
  ) {
    memberSecretBySecret(
      secret: $secret
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        memberID
        secret
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      authUserId
      firstName
      lastName
      email
      birthday
      genderIdentity
      isPrivate
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        authUserId
        firstName
        lastName
        email
        birthday
        genderIdentity
        isPrivate
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const userByAuthUserId = /* GraphQL */ `
  query UserByAuthUserId(
    $authUserId: String
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userByAuthUserId(
      authUserId: $authUserId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        authUserId
        firstName
        lastName
        email
        birthday
        genderIdentity
        isPrivate
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const usersByEmail = /* GraphQL */ `
  query UsersByEmail(
    $email: String
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    usersByEmail(
      email: $email
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        authUserId
        firstName
        lastName
        email
        birthday
        genderIdentity
        isPrivate
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getDoNotEmail = /* GraphQL */ `
  query GetDoNotEmail($id: ID!) {
    getDoNotEmail(id: $id) {
      id
      email
      description
      createdAt
      updatedAt
    }
  }
`;
export const listDoNotEmails = /* GraphQL */ `
  query ListDoNotEmails(
    $filter: ModelDoNotEmailFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDoNotEmails(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        email
        description
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const doNotEmailByEmail = /* GraphQL */ `
  query DoNotEmailByEmail(
    $email: String
    $sortDirection: ModelSortDirection
    $filter: ModelDoNotEmailFilterInput
    $limit: Int
    $nextToken: String
  ) {
    doNotEmailByEmail(
      email: $email
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        email
        description
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getAchievement = /* GraphQL */ `
  query GetAchievement($id: ID!) {
    getAchievement(id: $id) {
      id
      clubID
      name
      description
      type
      imageUrl
      value
      start
      end
      swag
      deletedAt
      createdAt
      updatedAt
    }
  }
`;
export const listAchievements = /* GraphQL */ `
  query ListAchievements(
    $filter: ModelAchievementFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAchievements(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        clubID
        name
        description
        type
        imageUrl
        value
        start
        end
        swag
        deletedAt
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const achievmentsByClub = /* GraphQL */ `
  query AchievmentsByClub(
    $clubID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelAchievementFilterInput
    $limit: Int
    $nextToken: String
  ) {
    achievmentsByClub(
      clubID: $clubID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        clubID
        name
        description
        type
        imageUrl
        value
        start
        end
        swag
        deletedAt
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getMemberAchievement = /* GraphQL */ `
  query GetMemberAchievement($id: ID!) {
    getMemberAchievement(id: $id) {
      id
      memberID
      achievementID
      clubID
      checkinID
      dateAchieved
      hasSwag
      swagReceived
      swagReceivedDate
      isNew
      status
      createdAt
      updatedAt
      achievement {
        id
        clubID
        name
        description
        type
        imageUrl
        value
        start
        end
        swag
        deletedAt
        createdAt
        updatedAt
      }
      club {
        id
        owner
        name
        address
        clubType
        city
        state
        zipcode
        latitude
        longitude
        scoreUnits
        hashedPinCode
        finishedText
        customMaxScore
        showCustomOption
        createdAt
        updatedAt
        deletedAt
        managerGroup
        logoUrl
        routesPathsUrl
        isPublic
        schedule {
          monday
          tuesday
          wednesday
          thursday
          friday
          saturday
          sunday
          mondayAddress
          tuesdayAddress
          wednesdayAddress
          thursdayAddress
          fridayAddress
          saturdayAddress
          sundayAddress
        }
        wantsWeeklyStats
        achievments {
          nextToken
        }
        checkinOptions {
          nextToken
        }
        members {
          nextToken
        }
      }
      member {
        id
        owner
        firstName
        lastName
        email
        genderIdentity
        birthday
        totalScore
        clubID
        userId
        managers
        createdAt
        updatedAt
        deletedAt
        memberAchievements {
          nextToken
        }
        club {
          id
          owner
          name
          address
          clubType
          city
          state
          zipcode
          latitude
          longitude
          scoreUnits
          hashedPinCode
          finishedText
          customMaxScore
          showCustomOption
          createdAt
          updatedAt
          deletedAt
          managerGroup
          logoUrl
          routesPathsUrl
          isPublic
          wantsWeeklyStats
        }
        checkins {
          nextToken
        }
      }
      checkin {
        id
        owner
        date
        score
        label
        memberID
        clubID
        weather {
          apparentTemperature
          cloudCover
          dewPoint
          humidity
          nearestStormDistance
          nearestStormBearing
          precipIntensity
          precipProbability
          precipType
          sunriseTime
          sunsetTime
          temperature
        }
        createdAt
        updatedAt
        member {
          id
          owner
          firstName
          lastName
          email
          genderIdentity
          birthday
          totalScore
          clubID
          userId
          managers
          createdAt
          updatedAt
          deletedAt
        }
      }
    }
  }
`;
export const listMemberAchievements = /* GraphQL */ `
  query ListMemberAchievements(
    $filter: ModelMemberAchievementFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMemberAchievements(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        memberID
        achievementID
        clubID
        checkinID
        dateAchieved
        hasSwag
        swagReceived
        swagReceivedDate
        isNew
        status
        createdAt
        updatedAt
        achievement {
          id
          clubID
          name
          description
          type
          imageUrl
          value
          start
          end
          swag
          deletedAt
          createdAt
          updatedAt
        }
        club {
          id
          owner
          name
          address
          clubType
          city
          state
          zipcode
          latitude
          longitude
          scoreUnits
          hashedPinCode
          finishedText
          customMaxScore
          showCustomOption
          createdAt
          updatedAt
          deletedAt
          managerGroup
          logoUrl
          routesPathsUrl
          isPublic
          wantsWeeklyStats
        }
        member {
          id
          owner
          firstName
          lastName
          email
          genderIdentity
          birthday
          totalScore
          clubID
          userId
          managers
          createdAt
          updatedAt
          deletedAt
        }
        checkin {
          id
          owner
          date
          score
          label
          memberID
          clubID
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const memberAchievementsByMember = /* GraphQL */ `
  query MemberAchievementsByMember(
    $memberID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelMemberAchievementFilterInput
    $limit: Int
    $nextToken: String
  ) {
    memberAchievementsByMember(
      memberID: $memberID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        memberID
        achievementID
        clubID
        checkinID
        dateAchieved
        hasSwag
        swagReceived
        swagReceivedDate
        isNew
        status
        createdAt
        updatedAt
        achievement {
          id
          clubID
          name
          description
          type
          imageUrl
          value
          start
          end
          swag
          deletedAt
          createdAt
          updatedAt
        }
        club {
          id
          owner
          name
          address
          clubType
          city
          state
          zipcode
          latitude
          longitude
          scoreUnits
          hashedPinCode
          finishedText
          customMaxScore
          showCustomOption
          createdAt
          updatedAt
          deletedAt
          managerGroup
          logoUrl
          routesPathsUrl
          isPublic
          wantsWeeklyStats
        }
        member {
          id
          owner
          firstName
          lastName
          email
          genderIdentity
          birthday
          totalScore
          clubID
          userId
          managers
          createdAt
          updatedAt
          deletedAt
        }
        checkin {
          id
          owner
          date
          score
          label
          memberID
          clubID
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const memberAchievementsByAchievement = /* GraphQL */ `
  query MemberAchievementsByAchievement(
    $achievementID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelMemberAchievementFilterInput
    $limit: Int
    $nextToken: String
  ) {
    memberAchievementsByAchievement(
      achievementID: $achievementID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        memberID
        achievementID
        clubID
        checkinID
        dateAchieved
        hasSwag
        swagReceived
        swagReceivedDate
        isNew
        status
        createdAt
        updatedAt
        achievement {
          id
          clubID
          name
          description
          type
          imageUrl
          value
          start
          end
          swag
          deletedAt
          createdAt
          updatedAt
        }
        club {
          id
          owner
          name
          address
          clubType
          city
          state
          zipcode
          latitude
          longitude
          scoreUnits
          hashedPinCode
          finishedText
          customMaxScore
          showCustomOption
          createdAt
          updatedAt
          deletedAt
          managerGroup
          logoUrl
          routesPathsUrl
          isPublic
          wantsWeeklyStats
        }
        member {
          id
          owner
          firstName
          lastName
          email
          genderIdentity
          birthday
          totalScore
          clubID
          userId
          managers
          createdAt
          updatedAt
          deletedAt
        }
        checkin {
          id
          owner
          date
          score
          label
          memberID
          clubID
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const memberAchievementsByClub = /* GraphQL */ `
  query MemberAchievementsByClub(
    $clubID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelMemberAchievementFilterInput
    $limit: Int
    $nextToken: String
  ) {
    memberAchievementsByClub(
      clubID: $clubID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        memberID
        achievementID
        clubID
        checkinID
        dateAchieved
        hasSwag
        swagReceived
        swagReceivedDate
        isNew
        status
        createdAt
        updatedAt
        achievement {
          id
          clubID
          name
          description
          type
          imageUrl
          value
          start
          end
          swag
          deletedAt
          createdAt
          updatedAt
        }
        club {
          id
          owner
          name
          address
          clubType
          city
          state
          zipcode
          latitude
          longitude
          scoreUnits
          hashedPinCode
          finishedText
          customMaxScore
          showCustomOption
          createdAt
          updatedAt
          deletedAt
          managerGroup
          logoUrl
          routesPathsUrl
          isPublic
          wantsWeeklyStats
        }
        member {
          id
          owner
          firstName
          lastName
          email
          genderIdentity
          birthday
          totalScore
          clubID
          userId
          managers
          createdAt
          updatedAt
          deletedAt
        }
        checkin {
          id
          owner
          date
          score
          label
          memberID
          clubID
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const memberAchievementsByCheckin = /* GraphQL */ `
  query MemberAchievementsByCheckin(
    $checkinID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelMemberAchievementFilterInput
    $limit: Int
    $nextToken: String
  ) {
    memberAchievementsByCheckin(
      checkinID: $checkinID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        memberID
        achievementID
        clubID
        checkinID
        dateAchieved
        hasSwag
        swagReceived
        swagReceivedDate
        isNew
        status
        createdAt
        updatedAt
        achievement {
          id
          clubID
          name
          description
          type
          imageUrl
          value
          start
          end
          swag
          deletedAt
          createdAt
          updatedAt
        }
        club {
          id
          owner
          name
          address
          clubType
          city
          state
          zipcode
          latitude
          longitude
          scoreUnits
          hashedPinCode
          finishedText
          customMaxScore
          showCustomOption
          createdAt
          updatedAt
          deletedAt
          managerGroup
          logoUrl
          routesPathsUrl
          isPublic
          wantsWeeklyStats
        }
        member {
          id
          owner
          firstName
          lastName
          email
          genderIdentity
          birthday
          totalScore
          clubID
          userId
          managers
          createdAt
          updatedAt
          deletedAt
        }
        checkin {
          id
          owner
          date
          score
          label
          memberID
          clubID
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const memberAchievementsByClubByStatus = /* GraphQL */ `
  query MemberAchievementsByClubByStatus(
    $clubID: ID
    $status: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMemberAchievementFilterInput
    $limit: Int
    $nextToken: String
  ) {
    memberAchievementsByClubByStatus(
      clubID: $clubID
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        memberID
        achievementID
        clubID
        checkinID
        dateAchieved
        hasSwag
        swagReceived
        swagReceivedDate
        isNew
        status
        createdAt
        updatedAt
        achievement {
          id
          clubID
          name
          description
          type
          imageUrl
          value
          start
          end
          swag
          deletedAt
          createdAt
          updatedAt
        }
        club {
          id
          owner
          name
          address
          clubType
          city
          state
          zipcode
          latitude
          longitude
          scoreUnits
          hashedPinCode
          finishedText
          customMaxScore
          showCustomOption
          createdAt
          updatedAt
          deletedAt
          managerGroup
          logoUrl
          routesPathsUrl
          isPublic
          wantsWeeklyStats
        }
        member {
          id
          owner
          firstName
          lastName
          email
          genderIdentity
          birthday
          totalScore
          clubID
          userId
          managers
          createdAt
          updatedAt
          deletedAt
        }
        checkin {
          id
          owner
          date
          score
          label
          memberID
          clubID
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const memberAchievementsByClubByStatusByDate = /* GraphQL */ `
  query MemberAchievementsByClubByStatusByDate(
    $clubID: ID
    $statusDateAchieved: ModelMemberAchievementByClubByStatusByDateCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMemberAchievementFilterInput
    $limit: Int
    $nextToken: String
  ) {
    memberAchievementsByClubByStatusByDate(
      clubID: $clubID
      statusDateAchieved: $statusDateAchieved
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        memberID
        achievementID
        clubID
        checkinID
        dateAchieved
        hasSwag
        swagReceived
        swagReceivedDate
        isNew
        status
        createdAt
        updatedAt
        achievement {
          id
          clubID
          name
          description
          type
          imageUrl
          value
          start
          end
          swag
          deletedAt
          createdAt
          updatedAt
        }
        club {
          id
          owner
          name
          address
          clubType
          city
          state
          zipcode
          latitude
          longitude
          scoreUnits
          hashedPinCode
          finishedText
          customMaxScore
          showCustomOption
          createdAt
          updatedAt
          deletedAt
          managerGroup
          logoUrl
          routesPathsUrl
          isPublic
          wantsWeeklyStats
        }
        member {
          id
          owner
          firstName
          lastName
          email
          genderIdentity
          birthday
          totalScore
          clubID
          userId
          managers
          createdAt
          updatedAt
          deletedAt
        }
        checkin {
          id
          owner
          date
          score
          label
          memberID
          clubID
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getClub = /* GraphQL */ `
  query GetClub($id: ID!) {
    getClub(id: $id) {
      id
      owner
      name
      address
      clubType
      city
      state
      zipcode
      latitude
      longitude
      scoreUnits
      hashedPinCode
      finishedText
      customMaxScore
      showCustomOption
      createdAt
      updatedAt
      deletedAt
      managerGroup
      logoUrl
      routesPathsUrl
      isPublic
      schedule {
        monday
        tuesday
        wednesday
        thursday
        friday
        saturday
        sunday
        mondayAddress
        tuesdayAddress
        wednesdayAddress
        thursdayAddress
        fridayAddress
        saturdayAddress
        sundayAddress
      }
      wantsWeeklyStats
      achievments {
        items {
          id
          clubID
          name
          description
          type
          imageUrl
          value
          start
          end
          swag
          deletedAt
          createdAt
          updatedAt
        }
        nextToken
      }
      checkinOptions {
        items {
          id
          owner
          label
          score
          order
          clubID
          createdAt
          updatedAt
        }
        nextToken
      }
      members {
        items {
          id
          owner
          firstName
          lastName
          email
          genderIdentity
          birthday
          totalScore
          clubID
          userId
          managers
          createdAt
          updatedAt
          deletedAt
        }
        nextToken
      }
    }
  }
`;
export const listClubs = /* GraphQL */ `
  query ListClubs(
    $filter: ModelClubFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listClubs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        name
        address
        clubType
        city
        state
        zipcode
        latitude
        longitude
        scoreUnits
        hashedPinCode
        finishedText
        customMaxScore
        showCustomOption
        createdAt
        updatedAt
        deletedAt
        managerGroup
        logoUrl
        routesPathsUrl
        isPublic
        schedule {
          monday
          tuesday
          wednesday
          thursday
          friday
          saturday
          sunday
          mondayAddress
          tuesdayAddress
          wednesdayAddress
          thursdayAddress
          fridayAddress
          saturdayAddress
          sundayAddress
        }
        wantsWeeklyStats
        achievments {
          nextToken
        }
        checkinOptions {
          nextToken
        }
        members {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const clubByOwner = /* GraphQL */ `
  query ClubByOwner(
    $owner: String
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelClubFilterInput
    $limit: Int
    $nextToken: String
  ) {
    clubByOwner(
      owner: $owner
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        name
        address
        clubType
        city
        state
        zipcode
        latitude
        longitude
        scoreUnits
        hashedPinCode
        finishedText
        customMaxScore
        showCustomOption
        createdAt
        updatedAt
        deletedAt
        managerGroup
        logoUrl
        routesPathsUrl
        isPublic
        schedule {
          monday
          tuesday
          wednesday
          thursday
          friday
          saturday
          sunday
          mondayAddress
          tuesdayAddress
          wednesdayAddress
          thursdayAddress
          fridayAddress
          saturdayAddress
          sundayAddress
        }
        wantsWeeklyStats
        achievments {
          nextToken
        }
        checkinOptions {
          nextToken
        }
        members {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const clubsByOwnerAndDeletedAt = /* GraphQL */ `
  query ClubsByOwnerAndDeletedAt(
    $owner: String
    $deletedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelClubFilterInput
    $limit: Int
    $nextToken: String
  ) {
    clubsByOwnerAndDeletedAt(
      owner: $owner
      deletedAt: $deletedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        name
        address
        clubType
        city
        state
        zipcode
        latitude
        longitude
        scoreUnits
        hashedPinCode
        finishedText
        customMaxScore
        showCustomOption
        createdAt
        updatedAt
        deletedAt
        managerGroup
        logoUrl
        routesPathsUrl
        isPublic
        schedule {
          monday
          tuesday
          wednesday
          thursday
          friday
          saturday
          sunday
          mondayAddress
          tuesdayAddress
          wednesdayAddress
          thursdayAddress
          fridayAddress
          saturdayAddress
          sundayAddress
        }
        wantsWeeklyStats
        achievments {
          nextToken
        }
        checkinOptions {
          nextToken
        }
        members {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const searchClubs = /* GraphQL */ `
  query SearchClubs(
    $filter: SearchableClubFilterInput
    $sort: SearchableClubSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchClubs(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        owner
        name
        address
        clubType
        city
        state
        zipcode
        latitude
        longitude
        scoreUnits
        hashedPinCode
        finishedText
        customMaxScore
        showCustomOption
        createdAt
        updatedAt
        deletedAt
        managerGroup
        logoUrl
        routesPathsUrl
        isPublic
        schedule {
          monday
          tuesday
          wednesday
          thursday
          friday
          saturday
          sunday
          mondayAddress
          tuesdayAddress
          wednesdayAddress
          thursdayAddress
          fridayAddress
          saturdayAddress
          sundayAddress
        }
        wantsWeeklyStats
        achievments {
          nextToken
        }
        checkinOptions {
          nextToken
        }
        members {
          nextToken
        }
      }
      nextToken
      total
    }
  }
`;
export const getClubManager = /* GraphQL */ `
  query GetClubManager($id: ID!) {
    getClubManager(id: $id) {
      id
      userId
      clubId
      createdAt
      updatedAt
      user {
        id
        authUserId
        firstName
        lastName
        email
        birthday
        genderIdentity
        isPrivate
        createdAt
        updatedAt
        owner
      }
      club {
        id
        owner
        name
        address
        clubType
        city
        state
        zipcode
        latitude
        longitude
        scoreUnits
        hashedPinCode
        finishedText
        customMaxScore
        showCustomOption
        createdAt
        updatedAt
        deletedAt
        managerGroup
        logoUrl
        routesPathsUrl
        isPublic
        schedule {
          monday
          tuesday
          wednesday
          thursday
          friday
          saturday
          sunday
          mondayAddress
          tuesdayAddress
          wednesdayAddress
          thursdayAddress
          fridayAddress
          saturdayAddress
          sundayAddress
        }
        wantsWeeklyStats
        achievments {
          nextToken
        }
        checkinOptions {
          nextToken
        }
        members {
          nextToken
        }
      }
    }
  }
`;
export const listClubManagers = /* GraphQL */ `
  query ListClubManagers(
    $filter: ModelClubManagerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listClubManagers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        clubId
        createdAt
        updatedAt
        user {
          id
          authUserId
          firstName
          lastName
          email
          birthday
          genderIdentity
          isPrivate
          createdAt
          updatedAt
          owner
        }
        club {
          id
          owner
          name
          address
          clubType
          city
          state
          zipcode
          latitude
          longitude
          scoreUnits
          hashedPinCode
          finishedText
          customMaxScore
          showCustomOption
          createdAt
          updatedAt
          deletedAt
          managerGroup
          logoUrl
          routesPathsUrl
          isPublic
          wantsWeeklyStats
        }
      }
      nextToken
    }
  }
`;
export const clubManagersByClubId = /* GraphQL */ `
  query ClubManagersByClubId(
    $clubId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelClubManagerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    clubManagersByClubId(
      clubId: $clubId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        clubId
        createdAt
        updatedAt
        user {
          id
          authUserId
          firstName
          lastName
          email
          birthday
          genderIdentity
          isPrivate
          createdAt
          updatedAt
          owner
        }
        club {
          id
          owner
          name
          address
          clubType
          city
          state
          zipcode
          latitude
          longitude
          scoreUnits
          hashedPinCode
          finishedText
          customMaxScore
          showCustomOption
          createdAt
          updatedAt
          deletedAt
          managerGroup
          logoUrl
          routesPathsUrl
          isPublic
          wantsWeeklyStats
        }
      }
      nextToken
    }
  }
`;
export const getCheckinOption = /* GraphQL */ `
  query GetCheckinOption($id: ID!) {
    getCheckinOption(id: $id) {
      id
      owner
      label
      score
      order
      clubID
      createdAt
      updatedAt
    }
  }
`;
export const listCheckinOptions = /* GraphQL */ `
  query ListCheckinOptions(
    $filter: ModelCheckinOptionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCheckinOptions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        label
        score
        order
        clubID
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const checkinOptionsByClubId = /* GraphQL */ `
  query CheckinOptionsByClubId(
    $clubID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelCheckinOptionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    checkinOptionsByClubID(
      clubID: $clubID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        label
        score
        order
        clubID
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getMember = /* GraphQL */ `
  query GetMember($id: ID!) {
    getMember(id: $id) {
      id
      owner
      firstName
      lastName
      email
      genderIdentity
      birthday
      totalScore
      clubID
      userId
      managers
      createdAt
      updatedAt
      deletedAt
      memberAchievements {
        items {
          id
          memberID
          achievementID
          clubID
          checkinID
          dateAchieved
          hasSwag
          swagReceived
          swagReceivedDate
          isNew
          status
          createdAt
          updatedAt
        }
        nextToken
      }
      club {
        id
        owner
        name
        address
        clubType
        city
        state
        zipcode
        latitude
        longitude
        scoreUnits
        hashedPinCode
        finishedText
        customMaxScore
        showCustomOption
        createdAt
        updatedAt
        deletedAt
        managerGroup
        logoUrl
        routesPathsUrl
        isPublic
        schedule {
          monday
          tuesday
          wednesday
          thursday
          friday
          saturday
          sunday
          mondayAddress
          tuesdayAddress
          wednesdayAddress
          thursdayAddress
          fridayAddress
          saturdayAddress
          sundayAddress
        }
        wantsWeeklyStats
        achievments {
          nextToken
        }
        checkinOptions {
          nextToken
        }
        members {
          nextToken
        }
      }
      checkins {
        items {
          id
          owner
          date
          score
          label
          memberID
          clubID
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listMembers = /* GraphQL */ `
  query ListMembers(
    $filter: ModelMemberFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMembers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        firstName
        lastName
        email
        genderIdentity
        birthday
        totalScore
        clubID
        userId
        managers
        createdAt
        updatedAt
        deletedAt
        memberAchievements {
          nextToken
        }
        club {
          id
          owner
          name
          address
          clubType
          city
          state
          zipcode
          latitude
          longitude
          scoreUnits
          hashedPinCode
          finishedText
          customMaxScore
          showCustomOption
          createdAt
          updatedAt
          deletedAt
          managerGroup
          logoUrl
          routesPathsUrl
          isPublic
          wantsWeeklyStats
        }
        checkins {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const membersByClubId = /* GraphQL */ `
  query MembersByClubId(
    $clubID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelMemberFilterInput
    $limit: Int
    $nextToken: String
  ) {
    membersByClubID(
      clubID: $clubID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        firstName
        lastName
        email
        genderIdentity
        birthday
        totalScore
        clubID
        userId
        managers
        createdAt
        updatedAt
        deletedAt
        memberAchievements {
          nextToken
        }
        club {
          id
          owner
          name
          address
          clubType
          city
          state
          zipcode
          latitude
          longitude
          scoreUnits
          hashedPinCode
          finishedText
          customMaxScore
          showCustomOption
          createdAt
          updatedAt
          deletedAt
          managerGroup
          logoUrl
          routesPathsUrl
          isPublic
          wantsWeeklyStats
        }
        checkins {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const membersByClubAndDate = /* GraphQL */ `
  query MembersByClubAndDate(
    $clubID: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMemberFilterInput
    $limit: Int
    $nextToken: String
  ) {
    membersByClubAndDate(
      clubID: $clubID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        firstName
        lastName
        email
        genderIdentity
        birthday
        totalScore
        clubID
        userId
        managers
        createdAt
        updatedAt
        deletedAt
        memberAchievements {
          nextToken
        }
        club {
          id
          owner
          name
          address
          clubType
          city
          state
          zipcode
          latitude
          longitude
          scoreUnits
          hashedPinCode
          finishedText
          customMaxScore
          showCustomOption
          createdAt
          updatedAt
          deletedAt
          managerGroup
          logoUrl
          routesPathsUrl
          isPublic
          wantsWeeklyStats
        }
        checkins {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const memberByUserId = /* GraphQL */ `
  query MemberByUserId(
    $userId: String
    $sortDirection: ModelSortDirection
    $filter: ModelMemberFilterInput
    $limit: Int
    $nextToken: String
  ) {
    memberByUserId(
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        firstName
        lastName
        email
        genderIdentity
        birthday
        totalScore
        clubID
        userId
        managers
        createdAt
        updatedAt
        deletedAt
        memberAchievements {
          nextToken
        }
        club {
          id
          owner
          name
          address
          clubType
          city
          state
          zipcode
          latitude
          longitude
          scoreUnits
          hashedPinCode
          finishedText
          customMaxScore
          showCustomOption
          createdAt
          updatedAt
          deletedAt
          managerGroup
          logoUrl
          routesPathsUrl
          isPublic
          wantsWeeklyStats
        }
        checkins {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const memberByClubAndUserId = /* GraphQL */ `
  query MemberByClubAndUserId(
    $clubID: ID
    $userId: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMemberFilterInput
    $limit: Int
    $nextToken: String
  ) {
    memberByClubAndUserId(
      clubID: $clubID
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        firstName
        lastName
        email
        genderIdentity
        birthday
        totalScore
        clubID
        userId
        managers
        createdAt
        updatedAt
        deletedAt
        memberAchievements {
          nextToken
        }
        club {
          id
          owner
          name
          address
          clubType
          city
          state
          zipcode
          latitude
          longitude
          scoreUnits
          hashedPinCode
          finishedText
          customMaxScore
          showCustomOption
          createdAt
          updatedAt
          deletedAt
          managerGroup
          logoUrl
          routesPathsUrl
          isPublic
          wantsWeeklyStats
        }
        checkins {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const membersByEmail = /* GraphQL */ `
  query MembersByEmail(
    $email: String
    $sortDirection: ModelSortDirection
    $filter: ModelMemberFilterInput
    $limit: Int
    $nextToken: String
  ) {
    membersByEmail(
      email: $email
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        firstName
        lastName
        email
        genderIdentity
        birthday
        totalScore
        clubID
        userId
        managers
        createdAt
        updatedAt
        deletedAt
        memberAchievements {
          nextToken
        }
        club {
          id
          owner
          name
          address
          clubType
          city
          state
          zipcode
          latitude
          longitude
          scoreUnits
          hashedPinCode
          finishedText
          customMaxScore
          showCustomOption
          createdAt
          updatedAt
          deletedAt
          managerGroup
          logoUrl
          routesPathsUrl
          isPublic
          wantsWeeklyStats
        }
        checkins {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const membersByClubAndEmail = /* GraphQL */ `
  query MembersByClubAndEmail(
    $clubID: ID
    $email: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMemberFilterInput
    $limit: Int
    $nextToken: String
  ) {
    membersByClubAndEmail(
      clubID: $clubID
      email: $email
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        firstName
        lastName
        email
        genderIdentity
        birthday
        totalScore
        clubID
        userId
        managers
        createdAt
        updatedAt
        deletedAt
        memberAchievements {
          nextToken
        }
        club {
          id
          owner
          name
          address
          clubType
          city
          state
          zipcode
          latitude
          longitude
          scoreUnits
          hashedPinCode
          finishedText
          customMaxScore
          showCustomOption
          createdAt
          updatedAt
          deletedAt
          managerGroup
          logoUrl
          routesPathsUrl
          isPublic
          wantsWeeklyStats
        }
        checkins {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const searchMembers = /* GraphQL */ `
  query SearchMembers(
    $filter: SearchableMemberFilterInput
    $sort: SearchableMemberSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchMembers(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        owner
        firstName
        lastName
        email
        genderIdentity
        birthday
        totalScore
        clubID
        userId
        managers
        createdAt
        updatedAt
        deletedAt
        memberAchievements {
          nextToken
        }
        club {
          id
          owner
          name
          address
          clubType
          city
          state
          zipcode
          latitude
          longitude
          scoreUnits
          hashedPinCode
          finishedText
          customMaxScore
          showCustomOption
          createdAt
          updatedAt
          deletedAt
          managerGroup
          logoUrl
          routesPathsUrl
          isPublic
          wantsWeeklyStats
        }
        checkins {
          nextToken
        }
      }
      nextToken
      total
    }
  }
`;
export const getCheckin = /* GraphQL */ `
  query GetCheckin($id: ID!) {
    getCheckin(id: $id) {
      id
      owner
      date
      score
      label
      memberID
      clubID
      weather {
        apparentTemperature
        cloudCover
        dewPoint
        humidity
        nearestStormDistance
        nearestStormBearing
        precipIntensity
        precipProbability
        precipType
        sunriseTime
        sunsetTime
        temperature
      }
      createdAt
      updatedAt
      member {
        id
        owner
        firstName
        lastName
        email
        genderIdentity
        birthday
        totalScore
        clubID
        userId
        managers
        createdAt
        updatedAt
        deletedAt
        memberAchievements {
          nextToken
        }
        club {
          id
          owner
          name
          address
          clubType
          city
          state
          zipcode
          latitude
          longitude
          scoreUnits
          hashedPinCode
          finishedText
          customMaxScore
          showCustomOption
          createdAt
          updatedAt
          deletedAt
          managerGroup
          logoUrl
          routesPathsUrl
          isPublic
          wantsWeeklyStats
        }
        checkins {
          nextToken
        }
      }
    }
  }
`;
export const listCheckins = /* GraphQL */ `
  query ListCheckins(
    $filter: ModelCheckinFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCheckins(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        date
        score
        label
        memberID
        clubID
        weather {
          apparentTemperature
          cloudCover
          dewPoint
          humidity
          nearestStormDistance
          nearestStormBearing
          precipIntensity
          precipProbability
          precipType
          sunriseTime
          sunsetTime
          temperature
        }
        createdAt
        updatedAt
        member {
          id
          owner
          firstName
          lastName
          email
          genderIdentity
          birthday
          totalScore
          clubID
          userId
          managers
          createdAt
          updatedAt
          deletedAt
        }
      }
      nextToken
    }
  }
`;
export const checkinsByMemberId = /* GraphQL */ `
  query CheckinsByMemberId(
    $memberID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelCheckinFilterInput
    $limit: Int
    $nextToken: String
  ) {
    checkinsByMemberID(
      memberID: $memberID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        date
        score
        label
        memberID
        clubID
        weather {
          apparentTemperature
          cloudCover
          dewPoint
          humidity
          nearestStormDistance
          nearestStormBearing
          precipIntensity
          precipProbability
          precipType
          sunriseTime
          sunsetTime
          temperature
        }
        createdAt
        updatedAt
        member {
          id
          owner
          firstName
          lastName
          email
          genderIdentity
          birthday
          totalScore
          clubID
          userId
          managers
          createdAt
          updatedAt
          deletedAt
        }
      }
      nextToken
    }
  }
`;
export const checkinsByClubId = /* GraphQL */ `
  query CheckinsByClubId(
    $clubID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelCheckinFilterInput
    $limit: Int
    $nextToken: String
  ) {
    checkinsByClubID(
      clubID: $clubID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        date
        score
        label
        memberID
        clubID
        weather {
          apparentTemperature
          cloudCover
          dewPoint
          humidity
          nearestStormDistance
          nearestStormBearing
          precipIntensity
          precipProbability
          precipType
          sunriseTime
          sunsetTime
          temperature
        }
        createdAt
        updatedAt
        member {
          id
          owner
          firstName
          lastName
          email
          genderIdentity
          birthday
          totalScore
          clubID
          userId
          managers
          createdAt
          updatedAt
          deletedAt
        }
      }
      nextToken
    }
  }
`;
export const checkinsByClubIdAndDate = /* GraphQL */ `
  query CheckinsByClubIdAndDate(
    $clubID: ID
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCheckinFilterInput
    $limit: Int
    $nextToken: String
  ) {
    checkinsByClubIDAndDate(
      clubID: $clubID
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        date
        score
        label
        memberID
        clubID
        weather {
          apparentTemperature
          cloudCover
          dewPoint
          humidity
          nearestStormDistance
          nearestStormBearing
          precipIntensity
          precipProbability
          precipType
          sunriseTime
          sunsetTime
          temperature
        }
        createdAt
        updatedAt
        member {
          id
          owner
          firstName
          lastName
          email
          genderIdentity
          birthday
          totalScore
          clubID
          userId
          managers
          createdAt
          updatedAt
          deletedAt
        }
      }
      nextToken
    }
  }
`;
