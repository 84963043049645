import React, { ReactElement } from "react";
import { useStore } from "src/contexts/StoreContext";
import { useHistory } from "react-router-dom";
import Button from "@mui/material/Button";
import { Box, ListItem, Typography } from "@mui/material";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";

import EditIcon from "@mui/icons-material/Edit";
import { format } from "date-fns";
import { renderGenderIdentity } from "src/global/genderIdentities";

const ProfileDisplay = (): ReactElement => {
  const { currentUser } = useStore();
  const history = useHistory();

  const renderBirthday = () => {
    if (currentUser?.birthday) {
      return format(new Date(currentUser.birthday), "MM/dd/yyyy");
    }
    return "not specified";
  };

  return (
    <Box maxWidth="md" margin="0 auto">
      <div>
        <Box textAlign="right">
          <Button
            variant="outlined"
            color="primary"
            onClick={() => history.push("/profile/edit")}
            startIcon={<EditIcon />}>
            Edit
          </Button>
        </Box>
        <List>
          <ListItem sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography fontWeight="bold" variant="h6" mr={2}>
              first name
            </Typography>
            <Typography variant="h6" fontWeight="light">
              {currentUser?.firstName}
            </Typography>
          </ListItem>
          <Divider />
          <ListItem sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography fontWeight="bold" variant="h6" mr={2}>
              last name
            </Typography>
            <Typography variant="h6" fontWeight="light">
              {currentUser?.lastName}
            </Typography>
          </ListItem>
          <Divider />
          <ListItem sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography fontWeight="bold" variant="h6" mr={2}>
              email
            </Typography>
            <Typography variant="h6" fontWeight="light">
              {currentUser?.email}
            </Typography>
          </ListItem>
          <Divider />
          <ListItem sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography fontWeight="bold" variant="h6" mr={2}>
              gender
            </Typography>
            <Typography variant="h6" fontWeight="light">
              {renderGenderIdentity(
                currentUser?.genderIdentity || "decline-specify",
              )}
            </Typography>
          </ListItem>
          <Divider />
          <ListItem sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography fontWeight="bold" variant="h6" mr={2}>
              birthday
            </Typography>
            <Typography variant="h6" fontWeight="light">
              {renderBirthday()}
            </Typography>
          </ListItem>
        </List>
      </div>
    </Box>
  );
};

export default ProfileDisplay;
