import React, { ReactElement } from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import ProfileDisplay from "./ProfileDisplay";
import EditProfile from "./EditProfile";

const ProfileRoot = (): ReactElement => {
  const match = useRouteMatch();
  return (
    <Switch>
      <Route path={`${match.path}/edit`}>
        <EditProfile />
      </Route>
      <Route path={match.path}>
        <ProfileDisplay />
      </Route>
    </Switch>
  );
};

export default ProfileRoot;
