import React, { ReactElement, useCallback, useState } from "react";
import { useDispatch, useStore } from "src/contexts/StoreContext";
import { useHistory } from "react-router-dom";
import Button from "@mui/material/Button";
import { Box, Container, TextField } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import { formatISO } from "date-fns";
import { updateUser } from "./backend";
import ChevronLeft from "@mui/icons-material/ChevronLeft";
import DatePickerModal from "src/components/DatePickerModal";
import { format } from "date-fns";
import { mapGenderToChild } from "src/global/genderIdentities";
import { SelectChangeEvent } from "@mui/material";
import { Save } from "@mui/icons-material";

const ProfileRoot = (): ReactElement => {
  const { currentUser } = useStore();
  const dispatch = useDispatch();
  const history = useHistory();

  const [isSettingBirthday, setIsSettingBirthday] = useState<boolean>(false);

  const [firstName, setFirstName] = useState<string>(
    currentUser?.firstName || "",
  );
  const [lastName, setLastName] = useState<string>(currentUser?.lastName || "");
  const [genderIdentity, setGenderIdentity] = useState<string>(
    currentUser?.genderIdentity || "",
  );
  const [birthday, setBirthday] = useState<string | undefined>(
    currentUser?.birthday,
  );

  const handleBirthdayChange = useCallback((date: Date | null) => {
    if (date) {
      const formatted = formatISO(new Date(date));
      setBirthday(formatted);
      setIsSettingBirthday(false);
    }
  }, []);

  const handleChangeGenderIdentity = (event: SelectChangeEvent<string>) => {
    setGenderIdentity(event.target.value as string);
  };

  const handleOnSave = useCallback(async () => {
    if (currentUser && firstName && lastName && currentUser.email) {
      const user = await updateUser({
        id: currentUser.id,
        firstName,
        lastName,
        genderIdentity,
        birthday,
      });
      if (user) {
        dispatch({
          type: "SET_USER",
          payload: {
            user,
            email: currentUser.email,
          },
        });
        history.push("/profile");
      } else {
        alert("Error updating user. Please try again.");
      }
    }
  }, [
    currentUser,
    firstName,
    lastName,
    genderIdentity,
    birthday,
    dispatch,
    history,
  ]);

  return (
    <Container maxWidth="md">
      <Box>
        <Button
          variant="outlined"
          startIcon={<ChevronLeft />}
          onClick={() => history.push("/profile")}>
          Back to profile
        </Button>
      </Box>
      <Box>
        <TextField
          sx={{ marginTop: 3, marginBottom: 2 }}
          label="First name"
          id="edit-first-name"
          fullWidth
          variant="outlined"
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
        />
        <TextField
          sx={{ marginTop: 1, marginBottom: 2 }}
          label="Last name"
          id="edit-last-name"
          fullWidth
          variant="outlined"
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
        />
        <TextField
          sx={{ marginTop: 1, marginBottom: 2 }}
          label="Email"
          id="edit-email-name"
          fullWidth
          variant="outlined"
          disabled
          value={currentUser?.email}
        />
        <FormControl>
          <InputLabel
            htmlFor="edit-gender-identity"
            sx={{ backgroundColor: "white", paddingLeft: 1, paddingRight: 1 }}>
            Gender
          </InputLabel>
          <Select
            native
            fullWidth
            value={genderIdentity}
            onChange={handleChangeGenderIdentity}
            inputProps={{
              name: "genderIdentity",
              id: "edit-gender-identity",
            }}>
            {mapGenderToChild((key, val) => {
              return (
                <option key={`gi-${key}`} value={key}>
                  {val}
                </option>
              );
            })}
          </Select>
        </FormControl>
        <Box sx={{ marginTop: 1, marginBottom: 3 }}>
          <InputLabel htmlFor="edit-birthday">Birthday:</InputLabel>
          <Button
            id="edit-birthday"
            variant="outlined"
            onClick={() => setIsSettingBirthday(true)}>
            {!birthday && "Set Birthday"}
            {birthday && format(new Date(birthday), "MM-dd-yyyy")}
          </Button>
        </Box>
        <Button
          variant="contained"
          color="primary"
          onClick={handleOnSave}
          sx={{ fontSize: "1.5rem" }}
          startIcon={<Save />}
          fullWidth>
          Save
        </Button>
      </Box>
      <DatePickerModal
        title="Set Birthday"
        isOpen={isSettingBirthday}
        date={birthday ? new Date(birthday) : undefined}
        onCancel={() => setIsSettingBirthday(false)}
        onDateChanged={handleBirthdayChange}
      />
    </Container>
  );
};

export default ProfileRoot;
