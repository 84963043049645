import React, { ReactElement } from "react";

import { QueryClient, QueryClientProvider } from "react-query";
import { StoreProvider } from "src/contexts/StoreContext";
import Navigation from "./Navigation";

const queryClient = new QueryClient();

const MainApp = (): ReactElement => {
  return (
    <QueryClientProvider client={queryClient}>
      <StoreProvider>
        <Navigation />
      </StoreProvider>
    </QueryClientProvider>
  );
};

export default MainApp;
