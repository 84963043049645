/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createClubSecret = /* GraphQL */ `
  mutation CreateClubSecret(
    $input: CreateClubSecretInput!
    $condition: ModelClubSecretConditionInput
  ) {
    createClubSecret(input: $input, condition: $condition) {
      id
      clubID
      secret
      createdAt
      updatedAt
    }
  }
`;
export const updateClubSecret = /* GraphQL */ `
  mutation UpdateClubSecret(
    $input: UpdateClubSecretInput!
    $condition: ModelClubSecretConditionInput
  ) {
    updateClubSecret(input: $input, condition: $condition) {
      id
      clubID
      secret
      createdAt
      updatedAt
    }
  }
`;
export const deleteClubSecret = /* GraphQL */ `
  mutation DeleteClubSecret(
    $input: DeleteClubSecretInput!
    $condition: ModelClubSecretConditionInput
  ) {
    deleteClubSecret(input: $input, condition: $condition) {
      id
      clubID
      secret
      createdAt
      updatedAt
    }
  }
`;
export const createMemberSecret = /* GraphQL */ `
  mutation CreateMemberSecret(
    $input: CreateMemberSecretInput!
    $condition: ModelMemberSecretConditionInput
  ) {
    createMemberSecret(input: $input, condition: $condition) {
      id
      memberID
      secret
      createdAt
      updatedAt
    }
  }
`;
export const updateMemberSecret = /* GraphQL */ `
  mutation UpdateMemberSecret(
    $input: UpdateMemberSecretInput!
    $condition: ModelMemberSecretConditionInput
  ) {
    updateMemberSecret(input: $input, condition: $condition) {
      id
      memberID
      secret
      createdAt
      updatedAt
    }
  }
`;
export const deleteMemberSecret = /* GraphQL */ `
  mutation DeleteMemberSecret(
    $input: DeleteMemberSecretInput!
    $condition: ModelMemberSecretConditionInput
  ) {
    deleteMemberSecret(input: $input, condition: $condition) {
      id
      memberID
      secret
      createdAt
      updatedAt
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      authUserId
      firstName
      lastName
      email
      birthday
      genderIdentity
      isPrivate
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      authUserId
      firstName
      lastName
      email
      birthday
      genderIdentity
      isPrivate
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      authUserId
      firstName
      lastName
      email
      birthday
      genderIdentity
      isPrivate
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createDoNotEmail = /* GraphQL */ `
  mutation CreateDoNotEmail(
    $input: CreateDoNotEmailInput!
    $condition: ModelDoNotEmailConditionInput
  ) {
    createDoNotEmail(input: $input, condition: $condition) {
      id
      email
      description
      createdAt
      updatedAt
    }
  }
`;
export const updateDoNotEmail = /* GraphQL */ `
  mutation UpdateDoNotEmail(
    $input: UpdateDoNotEmailInput!
    $condition: ModelDoNotEmailConditionInput
  ) {
    updateDoNotEmail(input: $input, condition: $condition) {
      id
      email
      description
      createdAt
      updatedAt
    }
  }
`;
export const deleteDoNotEmail = /* GraphQL */ `
  mutation DeleteDoNotEmail(
    $input: DeleteDoNotEmailInput!
    $condition: ModelDoNotEmailConditionInput
  ) {
    deleteDoNotEmail(input: $input, condition: $condition) {
      id
      email
      description
      createdAt
      updatedAt
    }
  }
`;
export const createAchievement = /* GraphQL */ `
  mutation CreateAchievement(
    $input: CreateAchievementInput!
    $condition: ModelAchievementConditionInput
  ) {
    createAchievement(input: $input, condition: $condition) {
      id
      clubID
      name
      description
      type
      imageUrl
      value
      start
      end
      swag
      deletedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateAchievement = /* GraphQL */ `
  mutation UpdateAchievement(
    $input: UpdateAchievementInput!
    $condition: ModelAchievementConditionInput
  ) {
    updateAchievement(input: $input, condition: $condition) {
      id
      clubID
      name
      description
      type
      imageUrl
      value
      start
      end
      swag
      deletedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteAchievement = /* GraphQL */ `
  mutation DeleteAchievement(
    $input: DeleteAchievementInput!
    $condition: ModelAchievementConditionInput
  ) {
    deleteAchievement(input: $input, condition: $condition) {
      id
      clubID
      name
      description
      type
      imageUrl
      value
      start
      end
      swag
      deletedAt
      createdAt
      updatedAt
    }
  }
`;
export const createMemberAchievement = /* GraphQL */ `
  mutation CreateMemberAchievement(
    $input: CreateMemberAchievementInput!
    $condition: ModelMemberAchievementConditionInput
  ) {
    createMemberAchievement(input: $input, condition: $condition) {
      id
      memberID
      achievementID
      clubID
      checkinID
      dateAchieved
      hasSwag
      swagReceived
      swagReceivedDate
      isNew
      status
      createdAt
      updatedAt
      achievement {
        id
        clubID
        name
        description
        type
        imageUrl
        value
        start
        end
        swag
        deletedAt
        createdAt
        updatedAt
      }
      club {
        id
        owner
        name
        address
        clubType
        city
        state
        zipcode
        latitude
        longitude
        scoreUnits
        hashedPinCode
        finishedText
        customMaxScore
        showCustomOption
        createdAt
        updatedAt
        deletedAt
        managerGroup
        logoUrl
        routesPathsUrl
        isPublic
        schedule {
          monday
          tuesday
          wednesday
          thursday
          friday
          saturday
          sunday
          mondayAddress
          tuesdayAddress
          wednesdayAddress
          thursdayAddress
          fridayAddress
          saturdayAddress
          sundayAddress
        }
        wantsWeeklyStats
        achievments {
          nextToken
        }
        checkinOptions {
          nextToken
        }
        members {
          nextToken
        }
      }
      member {
        id
        owner
        firstName
        lastName
        email
        genderIdentity
        birthday
        totalScore
        clubID
        userId
        managers
        createdAt
        updatedAt
        deletedAt
        memberAchievements {
          nextToken
        }
        club {
          id
          owner
          name
          address
          clubType
          city
          state
          zipcode
          latitude
          longitude
          scoreUnits
          hashedPinCode
          finishedText
          customMaxScore
          showCustomOption
          createdAt
          updatedAt
          deletedAt
          managerGroup
          logoUrl
          routesPathsUrl
          isPublic
          wantsWeeklyStats
        }
        checkins {
          nextToken
        }
      }
      checkin {
        id
        owner
        date
        score
        label
        memberID
        clubID
        weather {
          apparentTemperature
          cloudCover
          dewPoint
          humidity
          nearestStormDistance
          nearestStormBearing
          precipIntensity
          precipProbability
          precipType
          sunriseTime
          sunsetTime
          temperature
        }
        createdAt
        updatedAt
        member {
          id
          owner
          firstName
          lastName
          email
          genderIdentity
          birthday
          totalScore
          clubID
          userId
          managers
          createdAt
          updatedAt
          deletedAt
        }
      }
    }
  }
`;
export const updateMemberAchievement = /* GraphQL */ `
  mutation UpdateMemberAchievement(
    $input: UpdateMemberAchievementInput!
    $condition: ModelMemberAchievementConditionInput
  ) {
    updateMemberAchievement(input: $input, condition: $condition) {
      id
      memberID
      achievementID
      clubID
      checkinID
      dateAchieved
      hasSwag
      swagReceived
      swagReceivedDate
      isNew
      status
      createdAt
      updatedAt
      achievement {
        id
        clubID
        name
        description
        type
        imageUrl
        value
        start
        end
        swag
        deletedAt
        createdAt
        updatedAt
      }
      club {
        id
        owner
        name
        address
        clubType
        city
        state
        zipcode
        latitude
        longitude
        scoreUnits
        hashedPinCode
        finishedText
        customMaxScore
        showCustomOption
        createdAt
        updatedAt
        deletedAt
        managerGroup
        logoUrl
        routesPathsUrl
        isPublic
        schedule {
          monday
          tuesday
          wednesday
          thursday
          friday
          saturday
          sunday
          mondayAddress
          tuesdayAddress
          wednesdayAddress
          thursdayAddress
          fridayAddress
          saturdayAddress
          sundayAddress
        }
        wantsWeeklyStats
        achievments {
          nextToken
        }
        checkinOptions {
          nextToken
        }
        members {
          nextToken
        }
      }
      member {
        id
        owner
        firstName
        lastName
        email
        genderIdentity
        birthday
        totalScore
        clubID
        userId
        managers
        createdAt
        updatedAt
        deletedAt
        memberAchievements {
          nextToken
        }
        club {
          id
          owner
          name
          address
          clubType
          city
          state
          zipcode
          latitude
          longitude
          scoreUnits
          hashedPinCode
          finishedText
          customMaxScore
          showCustomOption
          createdAt
          updatedAt
          deletedAt
          managerGroup
          logoUrl
          routesPathsUrl
          isPublic
          wantsWeeklyStats
        }
        checkins {
          nextToken
        }
      }
      checkin {
        id
        owner
        date
        score
        label
        memberID
        clubID
        weather {
          apparentTemperature
          cloudCover
          dewPoint
          humidity
          nearestStormDistance
          nearestStormBearing
          precipIntensity
          precipProbability
          precipType
          sunriseTime
          sunsetTime
          temperature
        }
        createdAt
        updatedAt
        member {
          id
          owner
          firstName
          lastName
          email
          genderIdentity
          birthday
          totalScore
          clubID
          userId
          managers
          createdAt
          updatedAt
          deletedAt
        }
      }
    }
  }
`;
export const deleteMemberAchievement = /* GraphQL */ `
  mutation DeleteMemberAchievement(
    $input: DeleteMemberAchievementInput!
    $condition: ModelMemberAchievementConditionInput
  ) {
    deleteMemberAchievement(input: $input, condition: $condition) {
      id
      memberID
      achievementID
      clubID
      checkinID
      dateAchieved
      hasSwag
      swagReceived
      swagReceivedDate
      isNew
      status
      createdAt
      updatedAt
      achievement {
        id
        clubID
        name
        description
        type
        imageUrl
        value
        start
        end
        swag
        deletedAt
        createdAt
        updatedAt
      }
      club {
        id
        owner
        name
        address
        clubType
        city
        state
        zipcode
        latitude
        longitude
        scoreUnits
        hashedPinCode
        finishedText
        customMaxScore
        showCustomOption
        createdAt
        updatedAt
        deletedAt
        managerGroup
        logoUrl
        routesPathsUrl
        isPublic
        schedule {
          monday
          tuesday
          wednesday
          thursday
          friday
          saturday
          sunday
          mondayAddress
          tuesdayAddress
          wednesdayAddress
          thursdayAddress
          fridayAddress
          saturdayAddress
          sundayAddress
        }
        wantsWeeklyStats
        achievments {
          nextToken
        }
        checkinOptions {
          nextToken
        }
        members {
          nextToken
        }
      }
      member {
        id
        owner
        firstName
        lastName
        email
        genderIdentity
        birthday
        totalScore
        clubID
        userId
        managers
        createdAt
        updatedAt
        deletedAt
        memberAchievements {
          nextToken
        }
        club {
          id
          owner
          name
          address
          clubType
          city
          state
          zipcode
          latitude
          longitude
          scoreUnits
          hashedPinCode
          finishedText
          customMaxScore
          showCustomOption
          createdAt
          updatedAt
          deletedAt
          managerGroup
          logoUrl
          routesPathsUrl
          isPublic
          wantsWeeklyStats
        }
        checkins {
          nextToken
        }
      }
      checkin {
        id
        owner
        date
        score
        label
        memberID
        clubID
        weather {
          apparentTemperature
          cloudCover
          dewPoint
          humidity
          nearestStormDistance
          nearestStormBearing
          precipIntensity
          precipProbability
          precipType
          sunriseTime
          sunsetTime
          temperature
        }
        createdAt
        updatedAt
        member {
          id
          owner
          firstName
          lastName
          email
          genderIdentity
          birthday
          totalScore
          clubID
          userId
          managers
          createdAt
          updatedAt
          deletedAt
        }
      }
    }
  }
`;
export const createClub = /* GraphQL */ `
  mutation CreateClub(
    $input: CreateClubInput!
    $condition: ModelClubConditionInput
  ) {
    createClub(input: $input, condition: $condition) {
      id
      owner
      name
      address
      clubType
      city
      state
      zipcode
      latitude
      longitude
      scoreUnits
      hashedPinCode
      finishedText
      customMaxScore
      showCustomOption
      createdAt
      updatedAt
      deletedAt
      managerGroup
      logoUrl
      routesPathsUrl
      isPublic
      schedule {
        monday
        tuesday
        wednesday
        thursday
        friday
        saturday
        sunday
        mondayAddress
        tuesdayAddress
        wednesdayAddress
        thursdayAddress
        fridayAddress
        saturdayAddress
        sundayAddress
      }
      wantsWeeklyStats
      achievments {
        items {
          id
          clubID
          name
          description
          type
          imageUrl
          value
          start
          end
          swag
          deletedAt
          createdAt
          updatedAt
        }
        nextToken
      }
      checkinOptions {
        items {
          id
          owner
          label
          score
          order
          clubID
          createdAt
          updatedAt
        }
        nextToken
      }
      members {
        items {
          id
          owner
          firstName
          lastName
          email
          genderIdentity
          birthday
          totalScore
          clubID
          userId
          managers
          createdAt
          updatedAt
          deletedAt
        }
        nextToken
      }
    }
  }
`;
export const updateClub = /* GraphQL */ `
  mutation UpdateClub(
    $input: UpdateClubInput!
    $condition: ModelClubConditionInput
  ) {
    updateClub(input: $input, condition: $condition) {
      id
      owner
      name
      address
      clubType
      city
      state
      zipcode
      latitude
      longitude
      scoreUnits
      hashedPinCode
      finishedText
      customMaxScore
      showCustomOption
      createdAt
      updatedAt
      deletedAt
      managerGroup
      logoUrl
      routesPathsUrl
      isPublic
      schedule {
        monday
        tuesday
        wednesday
        thursday
        friday
        saturday
        sunday
        mondayAddress
        tuesdayAddress
        wednesdayAddress
        thursdayAddress
        fridayAddress
        saturdayAddress
        sundayAddress
      }
      wantsWeeklyStats
      achievments {
        items {
          id
          clubID
          name
          description
          type
          imageUrl
          value
          start
          end
          swag
          deletedAt
          createdAt
          updatedAt
        }
        nextToken
      }
      checkinOptions {
        items {
          id
          owner
          label
          score
          order
          clubID
          createdAt
          updatedAt
        }
        nextToken
      }
      members {
        items {
          id
          owner
          firstName
          lastName
          email
          genderIdentity
          birthday
          totalScore
          clubID
          userId
          managers
          createdAt
          updatedAt
          deletedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteClub = /* GraphQL */ `
  mutation DeleteClub(
    $input: DeleteClubInput!
    $condition: ModelClubConditionInput
  ) {
    deleteClub(input: $input, condition: $condition) {
      id
      owner
      name
      address
      clubType
      city
      state
      zipcode
      latitude
      longitude
      scoreUnits
      hashedPinCode
      finishedText
      customMaxScore
      showCustomOption
      createdAt
      updatedAt
      deletedAt
      managerGroup
      logoUrl
      routesPathsUrl
      isPublic
      schedule {
        monday
        tuesday
        wednesday
        thursday
        friday
        saturday
        sunday
        mondayAddress
        tuesdayAddress
        wednesdayAddress
        thursdayAddress
        fridayAddress
        saturdayAddress
        sundayAddress
      }
      wantsWeeklyStats
      achievments {
        items {
          id
          clubID
          name
          description
          type
          imageUrl
          value
          start
          end
          swag
          deletedAt
          createdAt
          updatedAt
        }
        nextToken
      }
      checkinOptions {
        items {
          id
          owner
          label
          score
          order
          clubID
          createdAt
          updatedAt
        }
        nextToken
      }
      members {
        items {
          id
          owner
          firstName
          lastName
          email
          genderIdentity
          birthday
          totalScore
          clubID
          userId
          managers
          createdAt
          updatedAt
          deletedAt
        }
        nextToken
      }
    }
  }
`;
export const createClubManager = /* GraphQL */ `
  mutation CreateClubManager(
    $input: CreateClubManagerInput!
    $condition: ModelClubManagerConditionInput
  ) {
    createClubManager(input: $input, condition: $condition) {
      id
      userId
      clubId
      createdAt
      updatedAt
      user {
        id
        authUserId
        firstName
        lastName
        email
        birthday
        genderIdentity
        isPrivate
        createdAt
        updatedAt
        owner
      }
      club {
        id
        owner
        name
        address
        clubType
        city
        state
        zipcode
        latitude
        longitude
        scoreUnits
        hashedPinCode
        finishedText
        customMaxScore
        showCustomOption
        createdAt
        updatedAt
        deletedAt
        managerGroup
        logoUrl
        routesPathsUrl
        isPublic
        schedule {
          monday
          tuesday
          wednesday
          thursday
          friday
          saturday
          sunday
          mondayAddress
          tuesdayAddress
          wednesdayAddress
          thursdayAddress
          fridayAddress
          saturdayAddress
          sundayAddress
        }
        wantsWeeklyStats
        achievments {
          nextToken
        }
        checkinOptions {
          nextToken
        }
        members {
          nextToken
        }
      }
    }
  }
`;
export const updateClubManager = /* GraphQL */ `
  mutation UpdateClubManager(
    $input: UpdateClubManagerInput!
    $condition: ModelClubManagerConditionInput
  ) {
    updateClubManager(input: $input, condition: $condition) {
      id
      userId
      clubId
      createdAt
      updatedAt
      user {
        id
        authUserId
        firstName
        lastName
        email
        birthday
        genderIdentity
        isPrivate
        createdAt
        updatedAt
        owner
      }
      club {
        id
        owner
        name
        address
        clubType
        city
        state
        zipcode
        latitude
        longitude
        scoreUnits
        hashedPinCode
        finishedText
        customMaxScore
        showCustomOption
        createdAt
        updatedAt
        deletedAt
        managerGroup
        logoUrl
        routesPathsUrl
        isPublic
        schedule {
          monday
          tuesday
          wednesday
          thursday
          friday
          saturday
          sunday
          mondayAddress
          tuesdayAddress
          wednesdayAddress
          thursdayAddress
          fridayAddress
          saturdayAddress
          sundayAddress
        }
        wantsWeeklyStats
        achievments {
          nextToken
        }
        checkinOptions {
          nextToken
        }
        members {
          nextToken
        }
      }
    }
  }
`;
export const deleteClubManager = /* GraphQL */ `
  mutation DeleteClubManager(
    $input: DeleteClubManagerInput!
    $condition: ModelClubManagerConditionInput
  ) {
    deleteClubManager(input: $input, condition: $condition) {
      id
      userId
      clubId
      createdAt
      updatedAt
      user {
        id
        authUserId
        firstName
        lastName
        email
        birthday
        genderIdentity
        isPrivate
        createdAt
        updatedAt
        owner
      }
      club {
        id
        owner
        name
        address
        clubType
        city
        state
        zipcode
        latitude
        longitude
        scoreUnits
        hashedPinCode
        finishedText
        customMaxScore
        showCustomOption
        createdAt
        updatedAt
        deletedAt
        managerGroup
        logoUrl
        routesPathsUrl
        isPublic
        schedule {
          monday
          tuesday
          wednesday
          thursday
          friday
          saturday
          sunday
          mondayAddress
          tuesdayAddress
          wednesdayAddress
          thursdayAddress
          fridayAddress
          saturdayAddress
          sundayAddress
        }
        wantsWeeklyStats
        achievments {
          nextToken
        }
        checkinOptions {
          nextToken
        }
        members {
          nextToken
        }
      }
    }
  }
`;
export const createCheckinOption = /* GraphQL */ `
  mutation CreateCheckinOption(
    $input: CreateCheckinOptionInput!
    $condition: ModelCheckinOptionConditionInput
  ) {
    createCheckinOption(input: $input, condition: $condition) {
      id
      owner
      label
      score
      order
      clubID
      createdAt
      updatedAt
    }
  }
`;
export const updateCheckinOption = /* GraphQL */ `
  mutation UpdateCheckinOption(
    $input: UpdateCheckinOptionInput!
    $condition: ModelCheckinOptionConditionInput
  ) {
    updateCheckinOption(input: $input, condition: $condition) {
      id
      owner
      label
      score
      order
      clubID
      createdAt
      updatedAt
    }
  }
`;
export const deleteCheckinOption = /* GraphQL */ `
  mutation DeleteCheckinOption(
    $input: DeleteCheckinOptionInput!
    $condition: ModelCheckinOptionConditionInput
  ) {
    deleteCheckinOption(input: $input, condition: $condition) {
      id
      owner
      label
      score
      order
      clubID
      createdAt
      updatedAt
    }
  }
`;
export const createMember = /* GraphQL */ `
  mutation CreateMember(
    $input: CreateMemberInput!
    $condition: ModelMemberConditionInput
  ) {
    createMember(input: $input, condition: $condition) {
      id
      owner
      firstName
      lastName
      email
      genderIdentity
      birthday
      totalScore
      clubID
      userId
      managers
      createdAt
      updatedAt
      deletedAt
      memberAchievements {
        items {
          id
          memberID
          achievementID
          clubID
          checkinID
          dateAchieved
          hasSwag
          swagReceived
          swagReceivedDate
          isNew
          status
          createdAt
          updatedAt
        }
        nextToken
      }
      club {
        id
        owner
        name
        address
        clubType
        city
        state
        zipcode
        latitude
        longitude
        scoreUnits
        hashedPinCode
        finishedText
        customMaxScore
        showCustomOption
        createdAt
        updatedAt
        deletedAt
        managerGroup
        logoUrl
        routesPathsUrl
        isPublic
        schedule {
          monday
          tuesday
          wednesday
          thursday
          friday
          saturday
          sunday
          mondayAddress
          tuesdayAddress
          wednesdayAddress
          thursdayAddress
          fridayAddress
          saturdayAddress
          sundayAddress
        }
        wantsWeeklyStats
        achievments {
          nextToken
        }
        checkinOptions {
          nextToken
        }
        members {
          nextToken
        }
      }
      checkins {
        items {
          id
          owner
          date
          score
          label
          memberID
          clubID
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateMember = /* GraphQL */ `
  mutation UpdateMember(
    $input: UpdateMemberInput!
    $condition: ModelMemberConditionInput
  ) {
    updateMember(input: $input, condition: $condition) {
      id
      owner
      firstName
      lastName
      email
      genderIdentity
      birthday
      totalScore
      clubID
      userId
      managers
      createdAt
      updatedAt
      deletedAt
      memberAchievements {
        items {
          id
          memberID
          achievementID
          clubID
          checkinID
          dateAchieved
          hasSwag
          swagReceived
          swagReceivedDate
          isNew
          status
          createdAt
          updatedAt
        }
        nextToken
      }
      club {
        id
        owner
        name
        address
        clubType
        city
        state
        zipcode
        latitude
        longitude
        scoreUnits
        hashedPinCode
        finishedText
        customMaxScore
        showCustomOption
        createdAt
        updatedAt
        deletedAt
        managerGroup
        logoUrl
        routesPathsUrl
        isPublic
        schedule {
          monday
          tuesday
          wednesday
          thursday
          friday
          saturday
          sunday
          mondayAddress
          tuesdayAddress
          wednesdayAddress
          thursdayAddress
          fridayAddress
          saturdayAddress
          sundayAddress
        }
        wantsWeeklyStats
        achievments {
          nextToken
        }
        checkinOptions {
          nextToken
        }
        members {
          nextToken
        }
      }
      checkins {
        items {
          id
          owner
          date
          score
          label
          memberID
          clubID
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteMember = /* GraphQL */ `
  mutation DeleteMember(
    $input: DeleteMemberInput!
    $condition: ModelMemberConditionInput
  ) {
    deleteMember(input: $input, condition: $condition) {
      id
      owner
      firstName
      lastName
      email
      genderIdentity
      birthday
      totalScore
      clubID
      userId
      managers
      createdAt
      updatedAt
      deletedAt
      memberAchievements {
        items {
          id
          memberID
          achievementID
          clubID
          checkinID
          dateAchieved
          hasSwag
          swagReceived
          swagReceivedDate
          isNew
          status
          createdAt
          updatedAt
        }
        nextToken
      }
      club {
        id
        owner
        name
        address
        clubType
        city
        state
        zipcode
        latitude
        longitude
        scoreUnits
        hashedPinCode
        finishedText
        customMaxScore
        showCustomOption
        createdAt
        updatedAt
        deletedAt
        managerGroup
        logoUrl
        routesPathsUrl
        isPublic
        schedule {
          monday
          tuesday
          wednesday
          thursday
          friday
          saturday
          sunday
          mondayAddress
          tuesdayAddress
          wednesdayAddress
          thursdayAddress
          fridayAddress
          saturdayAddress
          sundayAddress
        }
        wantsWeeklyStats
        achievments {
          nextToken
        }
        checkinOptions {
          nextToken
        }
        members {
          nextToken
        }
      }
      checkins {
        items {
          id
          owner
          date
          score
          label
          memberID
          clubID
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createCheckin = /* GraphQL */ `
  mutation CreateCheckin(
    $input: CreateCheckinInput!
    $condition: ModelCheckinConditionInput
  ) {
    createCheckin(input: $input, condition: $condition) {
      id
      owner
      date
      score
      label
      memberID
      clubID
      weather {
        apparentTemperature
        cloudCover
        dewPoint
        humidity
        nearestStormDistance
        nearestStormBearing
        precipIntensity
        precipProbability
        precipType
        sunriseTime
        sunsetTime
        temperature
      }
      createdAt
      updatedAt
      member {
        id
        owner
        firstName
        lastName
        email
        genderIdentity
        birthday
        totalScore
        clubID
        userId
        managers
        createdAt
        updatedAt
        deletedAt
        memberAchievements {
          nextToken
        }
        club {
          id
          owner
          name
          address
          clubType
          city
          state
          zipcode
          latitude
          longitude
          scoreUnits
          hashedPinCode
          finishedText
          customMaxScore
          showCustomOption
          createdAt
          updatedAt
          deletedAt
          managerGroup
          logoUrl
          routesPathsUrl
          isPublic
          wantsWeeklyStats
        }
        checkins {
          nextToken
        }
      }
    }
  }
`;
export const updateCheckin = /* GraphQL */ `
  mutation UpdateCheckin(
    $input: UpdateCheckinInput!
    $condition: ModelCheckinConditionInput
  ) {
    updateCheckin(input: $input, condition: $condition) {
      id
      owner
      date
      score
      label
      memberID
      clubID
      weather {
        apparentTemperature
        cloudCover
        dewPoint
        humidity
        nearestStormDistance
        nearestStormBearing
        precipIntensity
        precipProbability
        precipType
        sunriseTime
        sunsetTime
        temperature
      }
      createdAt
      updatedAt
      member {
        id
        owner
        firstName
        lastName
        email
        genderIdentity
        birthday
        totalScore
        clubID
        userId
        managers
        createdAt
        updatedAt
        deletedAt
        memberAchievements {
          nextToken
        }
        club {
          id
          owner
          name
          address
          clubType
          city
          state
          zipcode
          latitude
          longitude
          scoreUnits
          hashedPinCode
          finishedText
          customMaxScore
          showCustomOption
          createdAt
          updatedAt
          deletedAt
          managerGroup
          logoUrl
          routesPathsUrl
          isPublic
          wantsWeeklyStats
        }
        checkins {
          nextToken
        }
      }
    }
  }
`;
export const deleteCheckin = /* GraphQL */ `
  mutation DeleteCheckin(
    $input: DeleteCheckinInput!
    $condition: ModelCheckinConditionInput
  ) {
    deleteCheckin(input: $input, condition: $condition) {
      id
      owner
      date
      score
      label
      memberID
      clubID
      weather {
        apparentTemperature
        cloudCover
        dewPoint
        humidity
        nearestStormDistance
        nearestStormBearing
        precipIntensity
        precipProbability
        precipType
        sunriseTime
        sunsetTime
        temperature
      }
      createdAt
      updatedAt
      member {
        id
        owner
        firstName
        lastName
        email
        genderIdentity
        birthday
        totalScore
        clubID
        userId
        managers
        createdAt
        updatedAt
        deletedAt
        memberAchievements {
          nextToken
        }
        club {
          id
          owner
          name
          address
          clubType
          city
          state
          zipcode
          latitude
          longitude
          scoreUnits
          hashedPinCode
          finishedText
          customMaxScore
          showCustomOption
          createdAt
          updatedAt
          deletedAt
          managerGroup
          logoUrl
          routesPathsUrl
          isPublic
          wantsWeeklyStats
        }
        checkins {
          nextToken
        }
      }
    }
  }
`;
