import React, { useState, ReactElement, useCallback } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDoorOpen, faUser } from "@fortawesome/free-solid-svg-icons";
import { Auth } from "aws-amplify";
import { useDispatch, useStore } from "src/contexts/StoreContext";
import { createUser } from "./backend";
import Container from "@mui/material/Container";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  TextField,
} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { formatISO } from "date-fns";
import { format } from "date-fns";
import DatePickerModal from "src/components/DatePickerModal";
import { mapGenderToChild } from "src/global/genderIdentities";

const NewProfile = (): ReactElement => {
  const state = useStore();
  const dispatch = useDispatch();

  const [firstName, setFirstName] = useState<string | undefined>(undefined);
  const [lastName, setLastName] = useState<string | undefined>(undefined);
  const [genderIdentity, setGenderIdentity] = useState<string>("");
  const [birthday, setBirthday] = useState<string | undefined>(undefined);

  const [error, setError] = useState<string | null>(null);

  const [isSettingBirthday, setIsSettingBirthday] = useState<boolean>(false);

  const handleBirthdayChange = useCallback((date: Date | null) => {
    if (date) {
      const formatted = formatISO(new Date(date));
      setBirthday(formatted);
      setIsSettingBirthday(false);
    }
  }, []);

  const handleChangeGenderIdentity = (event: SelectChangeEvent<string>) => {
    setGenderIdentity(event.target.value as string);
  };

  const handleClickCreateUser = useCallback(async () => {
    const { email } = state;

    setError(null);

    try {
      const { username: authUserId } = await Auth.currentAuthenticatedUser();
      if (firstName && lastName) {
        const newUser = await createUser({
          email,
          firstName,
          lastName,
          genderIdentity,
          birthday,
          authUserId,
        });

        if (newUser) {
          dispatch({
            type: "SET_USER",
            payload: {
              user: newUser,
              email,
            },
          });
        } else {
          setError("Error creating user. Please try again later.");
        }
      } else {
        setError("First name and last name are required.");
      }
    } catch (e) {
      console.log("[ERROR] error handle create user", e);
      setError("Error creating user. Please try again later.");
    }
  }, [birthday, dispatch, firstName, genderIdentity, lastName, state]);

  return (
    <Container maxWidth="sm">
      <h2 className="d-flex" style={{ flex: 1 }}>
        New Profile
      </h2>
      <Card>
        <CardContent>
          <TextField
            id="first-name"
            variant="outlined"
            sx={{ mt: 3 }}
            fullWidth
            onChange={(e) => setFirstName(e.target.value)}
            placeholder="First name"
          />
          <TextField
            id="last-name"
            variant="outlined"
            sx={{ mt: 3 }}
            fullWidth
            onChange={(e) => setLastName(e.target.value)}
            placeholder="Last name"
          />
          <FormControl sx={{ mt: 3 }}>
            <InputLabel
              htmlFor="edit-gender-identity"
              sx={{ backgroundColor: "#fff", px: 1 }}>
              Gender
            </InputLabel>
            <Select
              native
              fullWidth
              value={genderIdentity}
              onChange={(e) => handleChangeGenderIdentity(e)}
              inputProps={{
                name: "genderIdentity",
                id: "edit-gender-identity",
              }}>
              {mapGenderToChild((key, val) => {
                return (
                  <option key={`gi-${key}`} value={key}>
                    {val}
                  </option>
                );
              })}
            </Select>
          </FormControl>

          <Box sx={{ mt: 3 }}>
            <InputLabel htmlFor="edit-birthday">Birthday: </InputLabel>
            <Button
              id="edit-birthday"
              variant="outlined"
              onClick={() => setIsSettingBirthday(true)}>
              {!birthday && "Set Birthday"}
              {birthday && format(new Date(birthday), "MMM dd yyyy")}
            </Button>
          </Box>
          {error && (
            <div
              className="row alert alert-danger mt-3 justify-content-between"
              role="alert">
              <span className="d-flex" style={{ flex: 1 }}>
                {error}
              </span>
              <button
                type="button"
                onClick={() => setError(null)}
                className="btn-close"
                aria-label="Close"></button>
            </div>
          )}
        </CardContent>
        <CardActions>
          <Button
            className="btn btn-primary"
            fullWidth
            color="primary"
            variant="contained"
            startIcon={<FontAwesomeIcon icon={faUser} swapOpacity />}
            onClick={handleClickCreateUser}>
            Create User
          </Button>
        </CardActions>
      </Card>
      <div>
        <Button
          size="small"
          variant="outlined"
          startIcon={<FontAwesomeIcon icon={faDoorOpen} swapOpacity />}
          onClick={() => Auth.signOut()}>
          Sign Out
        </Button>
      </div>
      <DatePickerModal
        title="Set Birthday"
        isOpen={isSettingBirthday}
        date={birthday ? new Date(birthday) : undefined}
        onCancel={() => setIsSettingBirthday(false)}
        onDateChanged={handleBirthdayChange}
      />
    </Container>
  );
};

export default NewProfile;
