import React, { ReactElement, useState } from "react";
import { Auth, Hub } from "aws-amplify";
import {
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  Container,
  FormControl,
  FormLabel,
  Modal,
  Paper,
  Typography,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Alert from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

import {
  UI_AUTH_CHANNEL,
  AUTH_STATE_CHANGE_EVENT,
  AuthState,
} from "@aws-amplify/ui-components";
import Box from "@mui/system/Box";

type SignInProps = {
  slot: string;
  email?: string;
};

type AWSError = {
  code?: string;
  message: string;
};

const ResetPassword = (props: SignInProps): ReactElement => {
  const [password, setPassword] = useState<string>("");
  const [code, setCode] = useState<string>("");
  const [email, setEmail] = useState<string>(props.email || "");

  const [error, setError] = useState<string | null>(null);
  const [isResetting, setIsResetting] = useState<boolean>(false);

  const onSendResetPasswordClicked = async () => {
    try {
      setIsResetting(true);
      if (!email) {
        setError("Email is required");
        return;
      }
      await Auth.forgotPasswordSubmit(email, code, password);

      Hub.dispatch(UI_AUTH_CHANNEL, {
        event: AUTH_STATE_CHANGE_EVENT,
        message: AuthState.SignIn,
        data: {
          passwordReset: true,
        },
      });
    } catch (_e) {
      const e = _e as AWSError;
      setError(e.message);
    } finally {
      setIsResetting(false);
    }
  };

  const onBackToSignInClicked = () => {
    Hub.dispatch(UI_AUTH_CHANNEL, {
      event: AUTH_STATE_CHANGE_EVENT,
      message: AuthState.SignIn,
    });
  };

  return (
    <Container slot={props.slot} maxWidth="sm" className="hydrated">
      <section className="section">
        <form
          onSubmit={() => alert("submitting")}
          onKeyUp={(e) => {
            if (e.key === "Enter") {
              onSendResetPasswordClicked();
            }
          }}>
          <Card>
            <CardContent>
              <Typography variant="h6">Reset password</Typography>
              {props.email && (
                <Alert severity="info">
                  We sent you an email with a code to {props.email}. Please use
                  that code to set a new password.
                </Alert>
              )}
              <FormControl fullWidth>
                <FormLabel required htmlFor="reset-code">
                  Code
                </FormLabel>
                <TextField
                  id="reset-code"
                  fullWidth
                  type="text"
                  autoCorrect="never"
                  value={code}
                  onChange={(e) => setCode(e.target.value)}
                  placeholder="Enter the code from your email"
                  variant="outlined"
                />
              </FormControl>
              <FormControl fullWidth>
                <FormLabel required htmlFor="email-address">
                  Email Address
                </FormLabel>
                <TextField
                  id="email-address"
                  fullWidth
                  type="email"
                  autoCorrect="never"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Enter your email address"
                  variant="outlined"
                />
              </FormControl>
              <FormControl fullWidth>
                <FormLabel required htmlFor="password">
                  New password
                </FormLabel>
                <TextField
                  id="password"
                  fullWidth
                  type="password"
                  autoCorrect="never"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Enter your new password"
                  variant="outlined"
                />
              </FormControl>
              <Typography variant="body2">
                Remembered your password?{" "}
                <Button
                  size="small"
                  onClick={onBackToSignInClicked}
                  color="primary">
                  Back to sign-in
                </Button>
              </Typography>
              {error && (
                <Alert
                  variant="filled"
                  severity="error"
                  action={
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={() => {
                        setError(null);
                      }}>
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  }>
                  {error}
                </Alert>
              )}
            </CardContent>
            <CardActions className="sign-in-form-footer">
              <div slot="amplify-form-section-footer">
                <slot name="footer">
                  <slot name="primary-footer-content">
                    <Button
                      onClick={onSendResetPasswordClicked}
                      size="large"
                      variant="contained"
                      color="primary"
                      disabled={isResetting}
                      className={`hydrated`}>
                      {isResetting ? "Resetting..." : "Reset password"}
                    </Button>
                    <Button onClick={onBackToSignInClicked}>Cancel</Button>
                  </slot>
                </slot>
              </div>
            </CardActions>
          </Card>
        </form>
      </section>
      <Modal open={isResetting}>
        <Box
          sx={{
            display: "flex",
            flex: 1,
            position: "fixed",
            left: 0,
            top: 0,
            bottom: 0,
            right: 0,
            alignItems: "center",
            justifyContent: "center",
          }}>
          <Paper
            sx={{
              padding: 5,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}>
            <Typography variant="h4">Reminding you...</Typography>
            <CircularProgress />
          </Paper>
        </Box>
      </Modal>
    </Container>
  );
};

export default ResetPassword;
