import React, { ReactElement, useEffect, useState } from "react";
import { Auth, Hub } from "aws-amplify";
import {
  Card,
  CardActions,
  CardContent,
  Container,
  FormControl,
  FormLabel,
  Typography,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Alert from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

import clsx from "clsx";

import {
  UI_AUTH_CHANNEL,
  AUTH_STATE_CHANGE_EVENT,
  AuthState,
} from "@aws-amplify/ui-components";

type ConfirmSignUpProps = {
  email?: string;
  slot: string;
};

const ConfirmSignUp = (props: ConfirmSignUpProps): ReactElement => {
  const [email, setEmail] = useState<string>(props.email || "");
  const [code, setCode] = useState<string>("");

  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const unsubscribe = Hub.listen(UI_AUTH_CHANNEL, (event) => {
      if (
        event.payload.event === AUTH_STATE_CHANGE_EVENT &&
        event.payload.message === AuthState.ConfirmSignUp
      ) {
        if (event.payload.data && event.payload.data.username) {
          setEmail(event.payload.data.username);
        }
      }
    });

    return () => {
      unsubscribe();
    };
  }, []);

  const onConfirmAccountClicked = async () => {
    try {
      await Auth.confirmSignUp(email, code);
      Hub.dispatch(UI_AUTH_CHANNEL, {
        event: AUTH_STATE_CHANGE_EVENT,
        message: AuthState.SignIn,
      });
    } catch (_e) {
      const e = _e as Error;
      setError(e.message);
    }
  };

  const onResendCodeClicked = async () => {
    try {
      await Auth.resendSignUp(email);
    } catch (_e) {
      const e = _e as Error;
      setError(e.message);
    }
  };

  const onSignInClicked = () => {
    Hub.dispatch(UI_AUTH_CHANNEL, {
      event: AUTH_STATE_CHANGE_EVENT,
      message: AuthState.SignIn,
    });
  };

  return (
    <Container slot={props.slot} maxWidth="sm" className="hydrated">
      <section className="section">
        <Card>
          <CardContent>
            <Typography variant="h6">Confirm Sign Up</Typography>
            <Alert severity="info">
              We have sent you an email with a validation code to be entered
              here. If you did not receive it, please press &quot;Resend
              Code&quot; below.
            </Alert>
            <FormControl fullWidth>
              <FormLabel required htmlFor="email-address">
                Email Address
              </FormLabel>
              <TextField
                id="email-address"
                fullWidth
                type="email"
                autoCorrect="never"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter your email address"
                variant="outlined"
              />
            </FormControl>
            <FormControl fullWidth>
              <FormLabel required htmlFor="code">
                Confirmation Code
              </FormLabel>
              <TextField
                id="code"
                fullWidth
                value={code}
                onChange={(e) => setCode(e.target.value)}
                placeholder="Enter your confirmation code"
                variant="outlined"
              />
            </FormControl>
            <Typography variant="body2">
              Lost your code?{" "}
              <Button
                size="small"
                color="primary"
                onClick={onResendCodeClicked}>
                Resend code
              </Button>
            </Typography>
            {error && (
              <Alert
                variant="filled"
                severity="error"
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setError(null);
                    }}>
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }>
                {error}
              </Alert>
            )}
          </CardContent>
          <CardActions className="sign-in-form-footer">
            <div slot="amplify-form-section-footer">
              <slot name="footer">
                <slot name="secondary-footer-content">
                  <span>
                    <Button
                      size="small"
                      color="primary"
                      onClick={onSignInClicked}
                      className="hydrated">
                      Back to Sign In
                    </Button>
                  </span>
                </slot>
                <slot name="primary-footer-content">
                  <Button
                    onClick={onConfirmAccountClicked}
                    size="large"
                    variant="contained"
                    color="primary"
                    className={clsx("hydrated")}>
                    <span>Confirm</span>
                  </Button>
                </slot>
              </slot>
            </div>
          </CardActions>
        </Card>
      </section>
    </Container>
  );
};

export default ConfirmSignUp;
