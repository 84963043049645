import React, { ReactElement, useState } from "react";
import { Auth, Hub } from "aws-amplify";
import {
  Card,
  CardActions,
  CardContent,
  Container,
  OutlinedInput,
  Typography,
} from "@mui/material";
import Button from "@mui/material/Button";
import Alert from "@mui/material/Alert";
import CloseIcon from "@mui/icons-material/Close";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import FormControl from "@mui/material/FormControl";

import {
  UI_AUTH_CHANNEL,
  AUTH_STATE_CHANGE_EVENT,
  AuthState,
} from "@aws-amplify/ui-components";
import InputLabel from "@mui/material/InputLabel";

type RegisterProps = {
  slot: string;
};

const Register = (props: RegisterProps): ReactElement => {
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [passwordConfirm, setPasswordConfirm] = useState<string>("");

  const [isShowPassword, setIsShowPassword] = useState<boolean>(false);

  const [error, setError] = useState<string | null>(null);

  const onRegisterPressed = async () => {
    if (password !== passwordConfirm) {
      setError("Passwords do not match");
      return;
    }
    try {
      setError(null);
      await Auth.signUp({
        username: email,
        password,
        attributes: {
          email,
        },
      });
      Hub.dispatch(UI_AUTH_CHANNEL, {
        event: AUTH_STATE_CHANGE_EVENT,
        message: AuthState.ConfirmSignUp,
        data: {
          username: email,
          email,
        },
      });
    } catch (_e) {
      const e = _e as Error;
      console.log("got the error", e);
      setError(e.message);
    }
  };

  const onSignInClicked = () => {
    Hub.dispatch(UI_AUTH_CHANNEL, {
      event: AUTH_STATE_CHANGE_EVENT,
      message: AuthState.SignIn,
    });
  };

  return (
    <Container slot={props.slot} maxWidth="sm" className="hydrated">
      <section className="section">
        <Card>
          <CardContent>
            <Typography variant="h6">Join</Typography>
            <FormControl fullWidth variant="outlined">
              <InputLabel
                required
                htmlFor="email-address"
                sx={{ backgroundColor: "#fff", px: 1 }}>
                Email Address
              </InputLabel>
              <OutlinedInput
                id="email-address"
                fullWidth
                type="email"
                autoCorrect="never"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </FormControl>
            <FormControl fullWidth variant="outlined" sx={{ mt: 3 }}>
              <InputLabel
                required
                htmlFor="password"
                sx={{ backgroundColor: "#fff", px: 1 }}>
                Password
              </InputLabel>
              <OutlinedInput
                id="password"
                fullWidth
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                type={isShowPassword ? "text" : "password"}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setIsShowPassword((v) => !v)}>
                      {isShowPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
            <FormControl fullWidth variant="outlined" sx={{ mt: 3 }}>
              <InputLabel
                required
                htmlFor="password-confirm"
                sx={{ backgroundColor: "#fff", px: 1 }}>
                Confirm Password
              </InputLabel>
              <OutlinedInput
                id="password-confirm"
                fullWidth
                value={passwordConfirm}
                onChange={(e) => setPasswordConfirm(e.target.value)}
                type={isShowPassword ? "text" : "password"}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setIsShowPassword((v) => !v)}>
                      {isShowPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
            {error && (
              <Alert
                style={{ marginTop: 20 }}
                variant="filled"
                severity="error"
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setError(null);
                    }}>
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }>
                {error}
              </Alert>
            )}
          </CardContent>
          <CardActions className="sign-in-form-footer">
            <div slot="amplify-form-section-footer">
              <slot name="footer">
                <slot name="secondary-footer-content">
                  <span>
                    Have an account?{" "}
                    <Button
                      size="small"
                      color="primary"
                      onClick={onSignInClicked}
                      className="hydrated">
                      Sign In
                    </Button>
                  </span>
                </slot>
                <slot name="primary-footer-content">
                  <Button
                    onClick={onRegisterPressed}
                    size="large"
                    variant="contained"
                    color="primary"
                    className={`hydrated`}>
                    <span>Create Account</span>
                  </Button>
                </slot>
              </slot>
            </div>
          </CardActions>
        </Card>
      </section>
    </Container>
  );
};

export default Register;
