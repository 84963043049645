import React, { ReactNode, ReactElement } from "react";
import User from "src/types/User";

type State = {
  loggedIn: boolean;
  email: string;
  currentUser: User | null;
};

type SetUserAction = {
  type: "SET_USER";
  payload: {
    user: User;
    email: string;
  };
};

type SignInAction = {
  type: "SIGNIN_USER";
  payload: string;
};

type SignOutAction = {
  type: "SIGNOUT_USER";
};

type Action = SetUserAction | SignInAction | SignOutAction;

const initialState: State = {
  loggedIn: false,
  email: "",
  currentUser: null,
};

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case "SIGNIN_USER":
      return {
        ...state,
        loggedIn: true,
        email: action.payload,
      };
    case "SET_USER":
      return {
        ...state,
        currentUser: action.payload.user,
        email: action.payload.email,
        loggedIn: true,
      };
    case "SIGNOUT_USER":
      return {
        ...state,
        currentUser: null,
        loggedIn: false,
      };
    default:
      return state;
  }
};

const storeContext = React.createContext<State>(initialState);
const dispatchContext = React.createContext<(a: Action) => void>(
  () => initialState,
);

type StoreProviderProps = {
  children: ReactNode;
};

const StoreProvider = ({ children }: StoreProviderProps): ReactElement => {
  const [store, dispatch] = React.useReducer(reducer, initialState);

  return (
    <storeContext.Provider value={store}>
      <dispatchContext.Provider value={dispatch}>
        {children}
      </dispatchContext.Provider>
    </storeContext.Provider>
  );
};

const useStore = (): State => {
  return React.useContext(storeContext);
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const useDispatch = () => {
  return React.useContext(dispatchContext);
};

export { StoreProvider, useStore, useDispatch };
