import { Auth, Hub } from "aws-amplify";
import React, { ReactElement, useCallback, useEffect, useState } from "react";

import { Switch, Route, useHistory } from "react-router-dom";
import { useDispatch, useStore } from "src/contexts/StoreContext";
import ClubRoot from "./Clubs";
import ProfileRoot from "./Profile";
import { getUserByAuthUserId } from "./Profile/backend";
import NewProfile from "./Profile/NewProfile";
import Drawer from "@mui/material/Drawer";

import GroupIcon from "@mui/icons-material/Group";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

import AccountCircle from "@mui/icons-material/AccountCircle";

// Material app bar
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import MeetingRoomIcon from "@mui/icons-material/MeetingRoom";
import { Alert, Button, Menu, MenuItem } from "@mui/material";
import { Box } from "@mui/system";

const Navigation = (): ReactElement => {
  const state = useStore();
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const history = useHistory();

  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);

  const [error, setError] = useState<string | null>(null);

  const handleDrawerNavigation = useCallback(
    (route: string) => {
      setIsDrawerOpen(false);
      history.push(route);
    },
    [history],
  );

  const handleLogoutPressed = async () => {
    const result = window.confirm("Are you sure you want to log out?");
    if (result) {
      await Auth.signOut();
      setTimeout(() => {
        window.location.href = "/";
      }, 100);
    }
  };

  useEffect(() => {
    const loadUser = async () => {
      const {
        username,
        attributes: { email },
      } = await Auth.currentAuthenticatedUser();
      const user = await getUserByAuthUserId(username);
      setError(null);
      if (user === null) {
        setError("Error finding user.");
      } else if (user) {
        dispatch({
          type: "SET_USER",
          payload: {
            user,
            email,
          },
        });
      } else {
        dispatch({
          type: "SIGNIN_USER",
          payload: email,
        });
      }
    };

    loadUser();

    Hub.listen("auth", (data) => {
      try {
        const event = data.payload.event;

        if (event === "signOut") {
          dispatch({
            type: "SIGNOUT_USER",
          });
        } else if (event === "signIn") {
          loadUser();
        }
      } catch (e) {
        console.log("[ERROR] error processing auth event", e);
      }
    });
  }, [dispatch]);

  if (error) {
    return (
      <Container maxWidth="md">
        <Alert
          sx={{
            marginTop: 5,
            display: "flex",
            flex: 1,
            alignItems: "center",
          }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              flex: 1,
              alignItems: "center",
            }}>
            <span>
              There was an error loading the app. Please refresh and try again.
            </span>
            <Button onClick={handleLogoutPressed} sx={{ marginLeft: 3 }}>
              Sign Out
            </Button>
          </Box>
        </Alert>
      </Container>
    );
  }

  if (!state.loggedIn) {
    return <div>Loading...</div>;
  }

  if (!state.currentUser) {
    return <NewProfile />;
  }
  return (
    <div>
      <div>
        <Drawer
          anchor={"left"}
          open={isDrawerOpen}
          onClose={() => setIsDrawerOpen(false)}>
          <div
            role="presentation"
            onClick={() => setIsDrawerOpen(false)}
            onKeyDown={() => setIsDrawerOpen(false)}>
            <List sx={{ minWidth: "300px" }}>
              <ListItem
                button
                key="Clubs"
                onClick={() => handleDrawerNavigation("/clubs")}>
                <ListItemIcon>
                  <GroupIcon />
                </ListItemIcon>
                <ListItemText primary="Clubs" />
              </ListItem>
              <ListItem
                button
                key="Profile"
                onClick={() => handleDrawerNavigation("/profile")}>
                <ListItemIcon>
                  <AccountCircleIcon />
                </ListItemIcon>
                <ListItemText primary="Profile" />
              </ListItem>
              <Divider />
              <ListItem button key="LogOut" onClick={handleLogoutPressed}>
                <ListItemIcon>
                  <MeetingRoomIcon color="error" />
                </ListItemIcon>
                <ListItemText>
                  <Typography color="error">Logout</Typography>
                </ListItemText>
              </ListItem>
            </List>
          </div>
        </Drawer>
        <AppBar position="static">
          <Container>
            <Toolbar>
              <IconButton
                edge="start"
                sx={{ display: { xs: "inherit", md: "none" } }}
                color="inherit"
                onClick={() => setIsDrawerOpen((val) => !val)}
                aria-label="open drawer">
                <MenuIcon />
              </IconButton>
              <Box sx={{ display: { xs: "none", md: "flex" }, flex: 1 }}>
                <Button
                  sx={{ my: 2, color: "white", display: "block" }}
                  key="club-link"
                  onClick={() => handleDrawerNavigation("/clubs")}>
                  Clubs
                </Button>
              </Box>
              <Box
                sx={{ display: { xs: "none", md: "inherit" } }}
                component="div">
                <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={(e) => setAnchorEl(e.currentTarget)}
                  color="inherit">
                  <AccountCircle />
                </IconButton>
                <Menu
                  open={Boolean(anchorEl)}
                  onClose={() => setAnchorEl(null)}
                  id="menu-appbar"
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}>
                  <MenuItem
                    key="profile-link"
                    onClick={() => {
                      setAnchorEl(null);
                      history.push("/profile");
                    }}>
                    Profile
                  </MenuItem>
                  <MenuItem key="signout-link" onClick={handleLogoutPressed}>
                    Logout
                  </MenuItem>
                </Menu>
              </Box>
            </Toolbar>
          </Container>
        </AppBar>
      </div>
      <Container sx={{ marginTop: 3 }}>
        <Alert severity="warning">
          We have moved all this functionality to the main website. Please sign
          in at{" "}
          <a href="https://runclub.beer/signin">https://runclub.beer/signin</a>
        </Alert>
        <Switch>
          <Route path="/profile">
            <ProfileRoot />
          </Route>
          <Route path="/clubs">
            <ClubRoot />
          </Route>
          <Route path="/">
            <ClubRoot />
          </Route>
        </Switch>
      </Container>
    </div>
  );
};

export default Navigation;
