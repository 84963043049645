import { Alert, List, ListItem, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { format } from "date-fns";
import { round } from "lodash";
import React, { useState, ReactElement } from "react";
import { useQuery, useQueryClient } from "react-query";
import { useHistory, useParams } from "react-router-dom";
import { useStore } from "src/contexts/StoreContext";
import { renderGenderIdentity } from "src/global/genderIdentities";
import { createMember, getClubById, getMembersByClubAndEmail } from "./backend";

const useClub = (clubId: string) => {
  return useQuery(["club", clubId], async () => {
    try {
      const club = getClubById(clubId);
      return club;
    } catch (e) {
      console.log("[ERROR] error using Club", e);
    }
  });
};

const useMemberByClubAndEmail = (clubId: string, email?: string) => {
  return useQuery(["club", clubId, "member", email], async () => {
    if (!email) {
      return null;
    }
    const result = await getMembersByClubAndEmail(clubId, email);
    return result;
  });
};

type JoinClubParams = {
  clubId: string;
};

const JoinClub = (): ReactElement => {
  const history = useHistory();
  const { clubId } = useParams<JoinClubParams>();
  const { currentUser } = useStore();
  const { data: club, status: clubStatus } = useClub(clubId);
  const { data: member, status: memberStatus } = useMemberByClubAndEmail(
    clubId,
    currentUser?.email,
  );
  const [error, setError] = useState<string | null>(null);

  const [isJoining, setIsJoining] = useState<boolean>(false);

  const client = useQueryClient();

  const handleClickJoinClub = async () => {
    try {
      if (currentUser && club) {
        setIsJoining(true);
        const result = await createMember({
          firstName: currentUser.firstName,
          lastName: currentUser.lastName,
          email: currentUser.email,
          userId: currentUser.id,
          clubID: club.id,
        });
        if (result) {
          history.push(`/clubs/${club.id}`);
          client.invalidateQueries([
            "club",
            clubId,
            "member",
            currentUser.email,
          ]);
        }
      } else {
        setError("Error joining club. Please try again.");
      }
    } catch (e) {
      console.log("[ERROR] error joining club", e);
      setError("Error joining club. Please try again.");
    }
  };

  const renderUserInfo = () => {
    if (currentUser) {
      const { firstName, lastName, email, genderIdentity, birthday } =
        currentUser;

      return (
        <List>
          <ListItem
            sx={{
              display: "flex",
              justifyContent: "space-between",
              borderBottom: "1px #dbdbdb solid",
            }}>
            <Typography variant="h6" fontWeight="bold" mr={2}>
              Name
            </Typography>
            <Typography variant="h6" fontWeight="light">
              {firstName} {lastName}
            </Typography>
          </ListItem>
          <ListItem
            sx={{
              display: "flex",
              justifyContent: "space-between",
              borderBottom: "1px #dbdbdb solid",
            }}>
            <Typography variant="h6" fontWeight="bold" mr={2}>
              Email
            </Typography>
            <Typography variant="h6" fontWeight="light">
              {email}
            </Typography>
          </ListItem>
          {genderIdentity && (
            <ListItem
              sx={{
                display: "flex",
                justifyContent: "space-between",
                borderBottom: "1px #dbdbdb solid",
              }}>
              <Typography variant="h6" fontWeight="bold" mr={2}>
                Gender
              </Typography>
              <Typography variant="h6" fontWeight="light">
                {renderGenderIdentity(genderIdentity)}
              </Typography>
            </ListItem>
          )}
          {birthday && (
            <ListItem
              sx={{
                display: "flex",
                justifyContent: "space-between",
                borderBottom: "1px #dbdbdb solid",
              }}>
              <Typography variant="h6" fontWeight="bold" mr={2}>
                Birthday
              </Typography>
              <Typography variant="h6" fontWeight="light">
                {format(new Date(birthday), "MM/dd/yyyy")}
              </Typography>
            </ListItem>
          )}
        </List>
      );
    }
  };

  const renderClubJoin = () => {
    if (member && club) {
      return (
        <div className="text-center mt-5">
          <p>
            You are already a member of <strong>{club.name}</strong>
          </p>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
            }}>
            <small style={{ marginRight: 5 }}>Score: </small>
            <h3>
              {round(member.totalScore, 1)} {club.scoreUnits}
            </h3>
          </div>
        </div>
      );
    }
    if (club) {
      return (
        <Box>
          <Typography variant="h5" mb={3}>
            Join {club.name} as...
          </Typography>
          <Box>
            <Box>
              {error && (
                <Alert variant="filled" severity="error">
                  {error}
                </Alert>
              )}
              {renderUserInfo()}
              <Button
                fullWidth
                color="primary"
                disabled={isJoining}
                onClick={handleClickJoinClub}
                variant="contained"
                sx={{ fontSize: "1.5rem", marginTop: 3 }}
                size="large">
                {isJoining ? "Joining" : "Join"}
              </Button>
            </Box>
          </Box>
        </Box>
      );
    }
  };

  /** Render Logic */

  if (clubStatus === "loading" || memberStatus === "loading") {
    return <div>Loading...</div>;
  }
  return (
    <Box
      maxWidth="md"
      margin="0 auto"
      sx={{ fontSize: "1.2em", textAlign: "center" }}>
      {renderClubJoin()}
    </Box>
  );
};

export default JoinClub;
