import API, { graphqlOperation, GraphQLResult } from "@aws-amplify/api";

import * as queries from "./queries";
import * as mutations from "src/graphql/mutations";
import Club from "src/types/Club";
import Member from "src/types/Member";

// This is defined above createMember even though
// it is alphabetically afterwards because it is
// used by createMember.
type GetMembersByClubAndEmail = {
  membersByClubAndEmail: { items: Array<Member> };
};

const getMembersByClubAndEmail = async (
  clubID: string,
  email: string,
): Promise<Member | null> => {
  try {
    const result = (await API.graphql(
      graphqlOperation(queries.membersByClubAndEmail, {
        clubID,
        email: { eq: email },
      }),
    )) as GraphQLResult<GetMembersByClubAndEmail>;

    if (
      result.data?.membersByClubAndEmail.items &&
      result.data?.membersByClubAndEmail.items.length > 0
    ) {
      return result.data.membersByClubAndEmail.items[0];
    }
    return null;
  } catch (e) {
    console.log("[ERROR] error membersByClubAndEmail", e);
    return null;
  }
};

type CreateMemberResult = {
  createMember: Member;
};

type CreateMemberProps = {
  firstName: string;
  lastName: string;
  email: string;
  clubID: string;
  userId: string;
};

const createMember = async ({
  firstName,
  lastName,
  email,
  clubID,
  userId,
}: CreateMemberProps): Promise<Member | null> => {
  try {
    const existingMember = await getMembersByClubAndEmail(clubID, email);
    if (existingMember) {
      return existingMember;
    }
    const result = (await API.graphql(
      graphqlOperation(mutations.createMember, {
        input: {
          firstName,
          lastName,
          email,
          clubID,
          userId,
          memberClubId: clubID,
          totalScore: 0,
        },
      }),
    )) as GraphQLResult<CreateMemberResult>;

    if (result.data?.createMember) {
      return result.data.createMember;
    }
    return null;
  } catch (e) {
    console.log("[ERROR] error creating member", e);
    return null;
  }
};

type GetMembersByUserId = {
  memberByUserId: { items: Member[] };
};

const getMembersByUserId = async (userId: string): Promise<Array<Member>> => {
  try {
    const result = (await API.graphql(
      graphqlOperation(queries.memberByUserId, {
        userId,
      }),
    )) as GraphQLResult<GetMembersByUserId>;

    if (result.data?.memberByUserId.items) {
      return result.data.memberByUserId.items;
    }
    return [];
  } catch (e) {
    console.log("[ERROR] error getting members by user id", e);
    return [];
  }
};

type GetMemberByClubIdAndUserId = {
  memberByClubAndUserId: { items: Member[] };
};

const getMemberByClubIdAndUserId = async (
  clubId: string,
  userId: string,
): Promise<Member | null> => {
  try {
    const result = (await API.graphql(
      graphqlOperation(queries.memberByClubAndUserId, {
        clubID: clubId,
        userId: { eq: userId },
      }),
    )) as GraphQLResult<GetMemberByClubIdAndUserId>;

    const members = result.data?.memberByClubAndUserId.items;
    if (members && members.length > 0) {
      return members[0];
    }
    return null;
  } catch (e) {
    console.log("[ERROR] error getting member by club id and user id", e);
    return null;
  }
};

type GetClubByIdResult = {
  getClub: Club;
};

const getClubById = async (clubId: string): Promise<Club | null> => {
  try {
    const result = (await API.graphql(
      graphqlOperation(queries.getClub, { id: clubId }),
    )) as GraphQLResult<GetClubByIdResult>;

    if (result.data?.getClub) {
      return result.data.getClub;
    }

    return null;
  } catch (e) {
    console.log("[ERROR] error getting club by id", e);
    return null;
  }
};

type SearchClubsByNameResults = {
  searchClubs: { items: Club[] };
};

const searchClubsByName = async (
  query: string,
): Promise<Array<Club> | null> => {
  try {
    const lowerQuery = query.toLowerCase();

    const results = (await API.graphql(
      graphqlOperation(queries.searchClubs, {
        filter: {
          or: [
            { name: { regexp: `.*${lowerQuery}.*` } },
            { name: { matchPhrase: lowerQuery } },
            { name: { matchPhrasePrefix: lowerQuery } },
            { city: { regexp: `.*${lowerQuery}.*` } },
            { city: { matchPhrase: lowerQuery } },
            { city: { matchPhrasePrefix: lowerQuery } },
          ],
        },
      }),
    )) as GraphQLResult<SearchClubsByNameResults>;

    if (results.data?.searchClubs.items) {
      return results.data?.searchClubs.items;
    }
    return [];
  } catch (e) {
    console.log("[ERROR] error searching for clubs by name", e);
    return null;
  }
};

export {
  createMember,
  getClubById,
  getMemberByClubIdAndUserId,
  getMembersByClubAndEmail,
  getMembersByUserId,
  searchClubsByName,
};
