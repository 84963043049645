export const getClub = /* GraphQL */ `
  query GetClub($id: ID!) {
    getClub(id: $id) {
      id
      owner
      name
      address
      city
      scoreUnits
      state
      zipcode
      logoUrl
      schedule {
        monday
        tuesday
        wednesday
        thursday
        friday
        saturday
        sunday
        mondayAddress
        tuesdayAddress
        wednesdayAddress
        thursdayAddress
        fridayAddress
        saturdayAddress
        sundayAddress
      }
    }
  }
`;

export const membersByClubAndEmail = /* GraphQL */ `
  query MembersByClubAndEmail(
    $clubID: ID
    $email: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMemberFilterInput
    $limit: Int
    $nextToken: String
  ) {
    membersByClubAndEmail(
      clubID: $clubID
      email: $email
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        firstName
        lastName
        totalScore
        clubID
        createdAt
        club {
          id
          name
          address
          clubType
          city
          state
          logoUrl
          routesPathsUrl
          scoreUnits
        }
      }
      nextToken
    }
  }
`;

export const memberByUserId = /* GraphQL */ `
  query MemberByUserId(
    $userId: String
    $sortDirection: ModelSortDirection
    $filter: ModelMemberFilterInput
    $limit: Int
    $nextToken: String
  ) {
    memberByUserId(
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        firstName
        lastName
        totalScore
        userId
        email
        club {
          id
          owner
          name
          logoUrl
          scoreUnits
        }
      }
      nextToken
    }
  }
`;

export const memberByClubAndUserId = /* GraphQL */ `
  query MemberByClubAndUserId(
    $clubID: ID
    $userId: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMemberFilterInput
    $limit: Int
    $nextToken: String
  ) {
    memberByClubAndUserId(
      clubID: $clubID
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        firstName
        lastName
        totalScore
        club {
          id
          owner
          name
          logoUrl
          scoreUnits
        }
      }
      nextToken
    }
  }
`;

export const searchClubs = /* GraphQL */ `
  query SearchClubs(
    $filter: SearchableClubFilterInput
    $sort: SearchableClubSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchClubs(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        owner
        name
        address
        clubType
        city
        state
        zipcode
        logoUrl
        schedule {
          monday
          tuesday
          wednesday
          thursday
          friday
          saturday
          sunday
          mondayAddress
          tuesdayAddress
          wednesdayAddress
          thursdayAddress
          fridayAddress
          saturdayAddress
          sundayAddress
        }
      }
      nextToken
      total
    }
  }
`;
