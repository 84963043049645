import API, { graphqlOperation, GraphQLResult } from "@aws-amplify/api";
import User from "src/types/User";

import * as queries from "../../graphql/queries";
import * as mutations from "../../graphql/mutations";

type CreateUserResult = {
  createUser: User;
};

type CreateUserProps = {
  firstName: string;
  lastName: string;
  email: string;
  authUserId: string;
  genderIdentity?: string;
  birthday?: string;
};

const createUser = async ({
  firstName,
  lastName,
  email,
  authUserId,
  genderIdentity,
  birthday,
}: CreateUserProps): Promise<User | null> => {
  try {
    const result = (await API.graphql(
      graphqlOperation(mutations.createUser, {
        input: {
          id: authUserId,
          authUserId,
          firstName,
          lastName,
          email,
          genderIdentity,
          birthday,
        },
      }),
    )) as GraphQLResult<CreateUserResult>;

    if (result.data?.createUser) {
      return result.data.createUser;
    }

    return null;
  } catch (e) {
    console.log("[ERROR] error creating user", e);
    return null;
  }
};

type GetuserByAuthUserIdResult = {
  userByAuthUserId: { items: User[] };
};

const getUserByAuthUserId = async (
  authUserId: string,
): Promise<User | false | null> => {
  try {
    const result = (await API.graphql(
      graphqlOperation(queries.userByAuthUserId, { authUserId }),
    )) as GraphQLResult<GetuserByAuthUserIdResult>;

    const users = result.data?.userByAuthUserId.items;
    if (users && users.length > 0) {
      return users[0];
    }
    return false;
  } catch (e) {
    console.log("[ERROR] error getting user by auth id", e);
    return null;
  }
};

type UpdateUserParams = {
  id: string;
  firstName: string;
  lastName: string;
  genderIdentity?: string;
  birthday?: string;
};

type UpdateUserResult = {
  updateUser: User;
};

const updateUser = async ({
  id,
  firstName,
  lastName,
  genderIdentity,
  birthday,
}: UpdateUserParams): Promise<User | null> => {
  try {
    const result = (await API.graphql(
      graphqlOperation(mutations.updateUser, {
        input: {
          id,
          firstName,
          lastName,
          genderIdentity,
          birthday,
        },
      }),
    )) as GraphQLResult<UpdateUserResult>;

    if (result.data?.updateUser) {
      return result.data.updateUser;
    }
    return null;
  } catch (e) {
    console.log("[ERROR] error updating user", e);
    return null;
  }
};

export { createUser, getUserByAuthUserId, updateUser };
