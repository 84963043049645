import { CardActions, CardContent, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Modal from "@mui/material/Modal";
import React, { ReactElement, useState } from "react";
import DatePicker from "./DatePicker";

type DatePickerModalProps = {
  title?: string;
  isOpen: boolean;
  date?: Date;
  onDateChanged: (val: Date) => void;
  onCancel: () => void;
};

const YEAR = new Date().getFullYear();

const DatePickerModal = ({
  title,
  isOpen,
  onDateChanged,
  date,
  onCancel,
}: DatePickerModalProps): ReactElement => {
  const [month, setMonth] = useState<string>(date ? `${date.getMonth()}` : "0");
  const [day, setDay] = useState<string>(date ? `${date.getDate()}` : "1");
  const [year, setYear] = useState<string>(
    date ? `${date.getFullYear()}` : `${YEAR}`,
  );

  const onDonePress = () => {
    onDateChanged(new Date(parseInt(year), parseInt(month), parseInt(day)));
  };

  return (
    <Modal open={isOpen}>
      <div
        style={{
          height: "100vh",
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
        }}>
        <Card sx={{ width: "90%", maxWidth: 500 }}>
          <CardContent>
            {title && <Typography variant="h5">{title}</Typography>}
            <DatePicker
              defaultDay={day}
              defaultMonth={month}
              defaultYear={year}
              onDayChange={setDay}
              onMonthChange={setMonth}
              onYearChange={setYear}
            />
            <CardActions
              style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button onClick={onCancel}>Cancel</Button>
              <Button variant="contained" color="primary" onClick={onDonePress}>
                Okay
              </Button>
            </CardActions>
          </CardContent>
        </Card>
      </div>
    </Modal>
  );
};

export default DatePickerModal;
