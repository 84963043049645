import React, { ReactElement, useState } from "react";
import { Auth, Hub } from "aws-amplify";
import {
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  Container,
  FormControl,
  FormLabel,
  Modal,
  Paper,
  Typography,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Alert from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

import {
  UI_AUTH_CHANNEL,
  AUTH_STATE_CHANGE_EVENT,
  AuthState,
} from "@aws-amplify/ui-components";
import Box from "@mui/system/Box";

type SignInProps = {
  slot: string;
  email?: string;
};

type AWSError = {
  code?: string;
  message: string;
};

const SignIn = (props: SignInProps): ReactElement => {
  const [email, setEmail] = useState<string>(props.email || "");
  const [password, setPassword] = useState<string>("");

  const [error, setError] = useState<string | null>(null);
  const [needsConfirm, setNeedsConfirm] = useState<boolean>(false);
  const [isSigningIn, setIsSigningIn] = useState<boolean>(false);

  const onSignInPressed = async () => {
    try {
      setIsSigningIn(true);
      await Auth.signIn(email, password);
      setPassword("");
      setEmail("");
      Hub.dispatch(AUTH_STATE_CHANGE_EVENT, {
        event: "SIGN_IN",
      });
    } catch (_e) {
      const e = _e as AWSError;
      if (e.code && e.code === "UserNotConfirmedException") {
        setNeedsConfirm(true);
      } else {
        setError(e.message);
      }
    } finally {
      setIsSigningIn(false);
    }
  };

  const onCreateAccountClicked = () => {
    setNeedsConfirm(false);
    Hub.dispatch(UI_AUTH_CHANNEL, {
      event: AUTH_STATE_CHANGE_EVENT,
      message: AuthState.SignUp,
    });
  };

  const onConfirmAccountClicked = () => {
    setNeedsConfirm(false);
    Hub.dispatch(UI_AUTH_CHANNEL, {
      event: AUTH_STATE_CHANGE_EVENT,
      message: AuthState.ConfirmSignUp,
      data: {
        username: email,
      },
    });
  };

  const onResetPasswordClicked = () => {
    setNeedsConfirm(false);
    Hub.dispatch(UI_AUTH_CHANNEL, {
      event: AUTH_STATE_CHANGE_EVENT,
      message: AuthState.ForgotPassword,
    });
  };

  return (
    <Container slot={props.slot} maxWidth="sm" className="hydrated">
      <section className="section">
        <form
          onSubmit={() => alert("submitting")}
          onKeyUp={(e) => {
            if (e.key === "Enter") {
              onSignInPressed();
            }
          }}>
          <Card>
            <CardContent>
              <Typography variant="h6">Sign in to your account</Typography>
              <FormControl fullWidth>
                <FormLabel required htmlFor="email-address">
                  Email Address
                </FormLabel>
                <TextField
                  id="email-address"
                  fullWidth
                  type="email"
                  autoCorrect="never"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Enter your email address"
                  variant="outlined"
                />
              </FormControl>
              <FormControl fullWidth>
                <FormLabel required htmlFor="password">
                  Password
                </FormLabel>
                <TextField
                  id="password"
                  fullWidth
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  type="password"
                  placeholder="Enter your password"
                  variant="outlined"
                />
              </FormControl>
              <Typography variant="body2">
                Forgot your password?{" "}
                <Button
                  size="small"
                  onClick={onResetPasswordClicked}
                  color="primary">
                  Reset Password
                </Button>
              </Typography>
              {needsConfirm && (
                <Alert
                  severity="error"
                  action={
                    <Button
                      size="small"
                      variant="contained"
                      color="primary"
                      onClick={onConfirmAccountClicked}>
                      Confirm Account
                    </Button>
                  }>
                  You need to confirm your account
                </Alert>
              )}
              {error && (
                <Alert
                  variant="filled"
                  severity="error"
                  action={
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={() => {
                        setError(null);
                      }}>
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  }>
                  {error}
                </Alert>
              )}
            </CardContent>
            <CardActions className="sign-in-form-footer">
              <div slot="amplify-form-section-footer">
                <slot name="footer">
                  <slot name="secondary-footer-content">
                    <span>
                      No account?{" "}
                      <Button
                        size="small"
                        color="primary"
                        onClick={onCreateAccountClicked}
                        className="hydrated">
                        Create account
                      </Button>
                    </span>
                  </slot>
                  <slot name="primary-footer-content">
                    <Button
                      onClick={onSignInPressed}
                      size="large"
                      variant="contained"
                      color="primary"
                      disabled={isSigningIn}
                      className={`hydrated`}>
                      {isSigningIn ? "Signing In" : "Sign In"}
                    </Button>
                  </slot>
                </slot>
              </div>
            </CardActions>
          </Card>
        </form>
      </section>
      <Modal open={isSigningIn}>
        <Box
          sx={{
            display: "flex",
            flex: 1,
            position: "fixed",
            left: 0,
            top: 0,
            bottom: 0,
            right: 0,
            alignItems: "center",
            justifyContent: "center",
          }}>
          <Paper
            sx={{
              padding: 5,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}>
            <Typography variant="h4">Signing in...</Typography>
            <CircularProgress />
          </Paper>
        </Box>
      </Modal>
    </Container>
  );
};

export default SignIn;
