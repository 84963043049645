import Container from "@mui/material/Container";
import { debounce } from "lodash";
import React, { useState, ReactElement, useRef, useCallback } from "react";
import { useHistory } from "react-router-dom";
import Club from "src/types/Club";
import { searchClubsByName } from "./backend";
import SearchIcon from "@mui/icons-material/Search";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Input from "@mui/material/Input";
import ListItem from "@mui/material/ListItem";
import List from "@mui/material/List";
import ListItemText from "@mui/material/ListItemText";

const ClubSearch = (): ReactElement => {
  const history = useHistory();
  const [searchResults, setSearchResults] = useState<Array<Club>>([]);
  const [isSearching, setIsSearching] = useState<boolean>(false);

  const [searchError, setSearchError] = useState<string | null>(null);
  const debouncedSearch = useRef(
    debounce(async (query: string) => {
      if (query.length > 2) {
        const clubs = await searchClubsByName(query);

        if (clubs) {
          setSearchResults(clubs);
        } else {
          setSearchError("Error searching. Please try again.");
        }
        setIsSearching(false);
      } else {
        setIsSearching(false);
      }
    }, 333),
  ).current;

  const handleChangeSearchQuery = useCallback(
    (query: string) => {
      setIsSearching(true);
      debouncedSearch(query);
    },
    [debouncedSearch],
  );

  const renderResults = () => {
    if (isSearching) {
      return (
        <div className="d-flex w-100 justify-content-center mt-3">
          Searching...
        </div>
      );
    }
    if (searchResults && searchResults.length > 0) {
      return (
        <List>
          {searchResults.map((club) => {
            return (
              <ListItem
                button
                key={`result-${club.id}`}
                onClick={() => history.push(`/clubs/${club.id}/join`)}>
                <ListItemText
                  primary={club.name}
                  secondary={`${club.city}, ${club.state}`}
                />
              </ListItem>
            );
          })}
        </List>
      );
    }
  };

  return (
    <Container>
      <div>
        {searchError && (
          <div className="alert alert-danger" role="alert">
            {searchError}
          </div>
        )}
        <form noValidate autoComplete="off">
          <FormControl fullWidth>
            <InputLabel htmlFor="search-adornment">
              Search clubs by name or city
            </InputLabel>
            <Input
              id="search-adornment"
              fullWidth
              placeholder="Club name or city"
              startAdornment={<SearchIcon />}
              type="search"
              onChange={(e) => handleChangeSearchQuery(e.target.value)}
            />
          </FormControl>
        </form>
        {renderResults()}
      </div>
    </Container>
  );
};

export default ClubSearch;
