import React, { ReactElement } from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import ClubHome from "./ClubHome";
import ClubList from "./ClubList";
import ClubSearch from "./ClubSearch";
import JoinClub from "./JoinClub";

const ClubRoot = (): ReactElement => {
  const match = useRouteMatch();
  return (
    <Switch>
      <Route path={`${match.path}/search`}>
        <ClubSearch />
      </Route>
      <Route path={`${match.path}/:clubId`} exact>
        <ClubHome />
      </Route>
      <Route path={`${match.path}/:clubId/join`}>
        <JoinClub />
      </Route>
      <Route path={match.path}>
        <ClubList />
      </Route>
    </Switch>
  );
};

export default ClubRoot;
